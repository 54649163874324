import { observer } from "mobx-react-lite";
import { MapStore } from "../../../../store/mapStore";
import React from "react";
import { useQuery } from "react-query";
import { getMetisCostUSD } from "../../../../api/coinGecko";
import { premiumTileCost, tileCost } from "../../../../const/const";
import { TileTypes } from "../../../../utils/mapUtils";

export const TilesCost = observer(({ mapStore }: { mapStore: MapStore }) => {
  const { isLoading, error, data } = useQuery(
    ["get-cost", mapStore.selectedTiles.length],
    getMetisCostUSD,
  );

  if (isLoading) {
    return <p>Total price: Loading...</p>;
  }

  if (error || !data) {
    return <p>Total price: Something went wrong.</p>;
  }

  if (!mapStore.availableToBuy) {
    return null;
  }

  if (mapStore.firsTileType === TileTypes.Premium) {
    return (
      <p>
        Total price:{" "}
        <span style={{ fontWeight: "bold" }}>
          ${(data * premiumTileCost * mapStore.selectedTiles.length).toFixed(4)}
        </span>
      </p>
    );
  }

  return (
    <p>
      Total price:{" "}
      <span style={{ fontWeight: "bold" }}>
        ${(data * tileCost * mapStore.selectedTiles.length).toFixed(4)}
      </span>
    </p>
  );
});
