import React, { FC } from "react";
import { Grid, Switch, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

interface MintedProps {
  isMintedLands?: boolean;
  toggleMintedLands?: () => void;
}

const Minted: FC<MintedProps> = ({ isMintedLands, toggleMintedLands }) => {
  const styles = useStyles();

  return (
    <Grid
      className={styles.minted}
      alignItems="center"
      justifyContent="space-between"
      container
    >
      <Grid item>
        <Typography className={styles.mintedText}>Minted</Typography>
      </Grid>
      <Grid item>
        <Switch
          checked={isMintedLands}
          onClick={toggleMintedLands}
          classes={{
            thumb: styles.thumb,
            track: styles.track,
            checked: styles.checked,
          }}
        />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  minted: {
    background:
      "var(--1, linear-gradient(265deg, #11C9E3 -7.11%, #BA15F4 103.55%))",
    borderRadius: "16px",
    width: "150px",
    height: "45px",
    color: "#fff",
    paddingLeft: "8px",
  },
  mintedText: {
    fontSize: "20px",
    fontFamily: "Space Grotesk",
  },
  thumb: {
    background: "#F37070",
    width: "19px",
    height: "19px",
    borderRadius: "2px",
    marginTop: "3px",
  },
  track: {
    background: "#CA7474",
    width: "40px",
    height: "20px",
    borderRadius: "2px",
    opacity: "1 !important",
  },
  checked: {
    "& > .MuiSwitch-thumb": {
      background: "#75E78E",
    },
    "& + .MuiSwitch-track": {
      backgroundColor: "#65C57A !important",
    },
  },
  "@media screen and (max-width: 695px)": {
    minted: {
      width: "100%",
      margin: 0,
      marginTop: "8px",
    },
  },
  "@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio:3)":
    {
      minted: {
        width: "100%",
        margin: 0,
        marginTop: "8px",
      },
    },
}));

export { Minted };
