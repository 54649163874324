import React from "react";
import {
  Button,
  Dialog,
  IconButton,
  Pagination,
  Skeleton,
  Grid,
} from "@mui/material";
import styles from "./styles.module.css";
import { Search } from "../Search/Search";
import CloseIcon from "@mui/icons-material/Close";
import { cutAddress } from "../../utils/format";
import Flag from "react-world-flags";
import { Premium } from "./Filters/Premium";
import { Minted } from "./Filters/Minted";
import { Countries } from "./Filters/Countries";
import useMediaQuery from "@mui/material/useMediaQuery";
import premiumBorder from "../../img/border.png";
import { formatMapboxUrl, index2coords, ZOOM } from "../../utils/mapUtils";

export interface RawLand {
  owner: string;
  tokenId: string;

  coordinates: {
    x: string;
    y: string;
  };

  country: string;
  countryName: string;
}

export interface Land {
  owner: string;
  tokenId: number;

  coordinates: {
    x: number;
    y: number;
  };

  country: string;
  countryName: string;
  isPremium?: boolean;
  isMinted?: boolean;
  premiumName?: string;
}

interface ViewLandsProps {
  open: boolean;
  handleClose: () => void;

  currentPage: number;
  pages: number;

  title?: string;
  isLoading?: boolean;
  searchEnabled?: boolean;
  error?: boolean;
  lands?: Land[];
  searchResults?: React.ReactElement;

  onPageChanged?: (page: number) => void;
  onSearch?: (query: string) => void;

  onView?: (coords: { x: number; y: number }) => void;
  query?: string;
  isMyLands?: boolean;
  isPremiumLands?: boolean;
  togglePremiumLands?: () => void;
  isMintedLands?: boolean;
  toggleMintedLands?: () => void;
  selectCountry?: (countryCode: string) => void;
}

export const ViewLands = (props: ViewLandsProps): React.ReactElement => {
  const matches = useMediaQuery("(max-width:690px)");

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      maxWidth="xl"
      fullWidth
      fullScreen={matches}
    >
      <div className={styles["dialog-title"]}>
        <Grid container alignItems="center">
          <Grid item className={styles["title-block-container"]}>
            <TitleBlock
              title={props.title}
              searchEnabled={props.searchEnabled}
              onSearch={props.onSearch}
              query={props.query}
              searchResults={props.searchResults}
            />
          </Grid>
          {!props.isMyLands && (
            <Grid item className={styles["filters-container"]}>
              <Filters
                isPremiumLands={props.isPremiumLands}
                togglePremiumLands={props.togglePremiumLands}
                isMintedLands={props.isMintedLands}
                toggleMintedLands={props.toggleMintedLands}
                selectCountry={props.selectCountry}
              />
            </Grid>
          )}
        </Grid>
        <IconButton
          aria-label="close"
          onClick={props.handleClose}
          sx={{ color: "black", marginLeft: "auto" }}
          style={{ top: "20px", right: "16px", position: "absolute" }}
        >
          <CloseIcon />
        </IconButton>
      </div>

      <div className={styles["dialog-content"]}>
        {!props.lands || !props.lands.length ? (
          <h3>This user doesn't have any lands</h3>
        ) : null}

        {!props.isLoading && !props.error && props.lands ? (
          <div>
            {props.lands!.map(
              ({
                owner,
                coordinates,
                tokenId,
                country,
                countryName,
                isPremium,
                isMinted,
                premiumName,
              }) => (
                <div key={tokenId} className={styles["earth-land"]}>
                  <div
                    style={{
                      marginTop: "10px",
                      width: 128,
                      height: 128,
                      position: "relative",
                      borderRadius: "4px",
                    }}
                  >
                    <img
                      style={{ width: 128, height: 128, borderRadius: "4px" }}
                      src={formatMapboxUrl(coordinates.x, coordinates.y, ZOOM)}
                    />
                    {isPremium && (
                      <img
                        src={premiumBorder}
                        alt="premium-border"
                        className={styles["premium-border"]}
                        style={{
                          width: 128,
                          height: 128,
                          borderRadius: "4px",
                        }}
                      />
                    )}
                  </div>
                  <div className={styles["land-info"]}>
                    <h4 style={{ marginBottom: 5 }}>
                      {isPremium && !isMinted ? (
                        "Mintable"
                      ) : (
                        <>
                          Owned by:{" "}
                          <a
                            target="_blank"
                            rel="noopener"
                            href={`https://base.blockscout.com/address/${owner}`}
                          >
                            {cutAddress(owner)}
                          </a>
                        </>
                      )}
                    </h4>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "fit-content",
                      }}
                    >
                      <h4 style={{ marginBottom: 0, marginTop: 0 }}>
                        {" "}
                        Country:
                      </h4>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Flag
                          code={country}
                          style={{ width: 20, height: 20, margin: "0 6px" }}
                        />

                        <h4 style={{ marginBottom: 0, marginTop: 0 }}>
                          {countryName}
                        </h4>
                      </div>
                    </div>
                    {premiumName && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "fit-content",
                        }}
                      >
                        <h4 style={{ paddingRight: "4px" }}>Place: </h4>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <h4>{premiumName}</h4>
                        </div>
                      </div>
                    )}
                  </div>

                  <Button
                    className={styles["view-btn"]}
                    onClick={() => props.onView?.(index2coords(tokenId))}
                  >
                    View
                  </Button>
                </div>
              )
            )}
          </div>
        ) : props.error ? (
          <h2>Something went wrong.</h2>
        ) : (
          <div>
            {Array(4)
              .fill(0)
              .map(() => (
                <div className={styles["earth-land"]}>
                  <Skeleton
                    variant="rectangular"
                    style={{ width: 128, height: 128 }}
                  />

                  <div className={styles["land-info"]}>
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                  </div>
                </div>
              ))}
          </div>
        )}

        <Pagination
          page={props.currentPage}
          onChange={(e, p) => props.onPageChanged?.(p)}
          count={props.pages}
          color="secondary"
          size="large"
        />
      </div>
    </Dialog>
  );
};

const Filters: React.FC<{
  isMyLands?: boolean;
  isPremiumLands?: boolean;
  togglePremiumLands?: () => void;
  isMintedLands?: boolean;
  toggleMintedLands?: () => void;
  selectCountry?: (countryCode: string) => void;
}> = ({
  isMyLands,
  isPremiumLands,
  togglePremiumLands,
  isMintedLands,
  toggleMintedLands,
  selectCountry,
}) => {
  return (
    <div
      style={{ display: "flex", alignItems: "center" }}
      className={styles["filters"]}
    >
      <Premium
        isPremiumLands={isPremiumLands}
        togglePremiumLands={togglePremiumLands}
      />
      <Countries
        selectCountry={selectCountry}
        isPremiumLands={isPremiumLands}
        isMintedLands={isMintedLands}
      />
      {isPremiumLands && (
        <Minted
          isMintedLands={isMintedLands}
          toggleMintedLands={toggleMintedLands}
        />
      )}
    </div>
  );
};

const TitleBlock: React.FC<{
  title?: string;
  isLoading?: boolean;
  searchEnabled?: boolean;
  query?: string;
  onSearch?: (query: string) => void;
  searchResults?: React.ReactElement;
}> = ({ title, searchEnabled, query, onSearch, searchResults }) => {
  return (
    <div
      style={{ display: "flex", alignItems: "center" }}
      className={styles["title-block"]}
    >
      <h2 className={styles["title"]}>{title}</h2>

      {searchEnabled ? (
        <Search
          onQueryChanged={onSearch}
          placeholder="Search by wallet address"
          style={{ marginLeft: "24px", maxWidth: 500, marginRight: "25px" }}
          query={query}
          timeout={0}
          className={styles["search-block"]}
        >
          {searchResults}
        </Search>
      ) : null}
    </div>
  );
};
