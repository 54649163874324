import React, { useState } from "react";
import styles from "./styles.module.css";
import { MapStore } from "../../store/mapStore";
import { observer } from "mobx-react-lite";
import { AppStore } from "../../store/appStore";
import { errorMessageEnv, premiumTileCost, tileCost } from "../../const/const";
import { TilesCost } from "./components/TilesCost/TilesCost";
import { AddressSearch } from "./components/AddressSearch/AddressSearch";
import { useSnackbar } from "notistack";
import { CircularProgress, Grid } from "@mui/material";
import { ViewMyLands } from "../../components/ViewLands/ViewMyLands";
import { ViewAllLands } from "../../components/ViewLands/ViewAllLands";
import Crown from "../../img/crown.svg";
import MyLands from "../../img/my_lands.svg";
import AllLands from "../../img/all_lands.svg";
import { Rewards } from "./components/Rewards";
import { TileTypes } from "../../utils/mapUtils";
import { getProofs } from "../../api/parser";
import { getValue, StorageKey } from "../../utils/storageUtils";
import ButtonWallet from "../../components/WalletConnect/ButtonWallet";

interface LandsMenuProps {
  appStore: AppStore;
  mapStore: MapStore;

  isMobile: boolean;
}

export const LandsMenu = observer(
  ({ appStore, mapStore, isMobile }: LandsMenuProps): React.ReactElement => {
    const [isLoading, setIsLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const [premiumLandsVisible, setPremiumLandsVisible] = useState(false);
    const [allLandsVisible, setIsAllLandsVisible] = useState(false);
    const [myLandsVisible, setIsMyLandsVisible] = useState(false);

    async function buy() {
      try {
        if (!mapStore.availableToBuy) {
          enqueueSnackbar(
            "You can not buy Premium land together with Regular land in the same transaction",
            { variant: "error" }
          );
          setIsLoading(false);

          mapStore.selectedTilesTypes = [];
          mapStore.selectedTiles = [];

          return;
        }

        // TODO: public mint date
        if (getValue(StorageKey.WL_PROOFS) === "") {
          if (Math.floor(Date.now() / 1000) < 1646499600) {
            enqueueSnackbar("Public mint has not started yet", {
              variant: "error",
            });

            setIsLoading(false);
            return;
          }
        } else {
          if (Math.floor(Date.now() / 1000) < 1646492400) {
            enqueueSnackbar("WL mint has not started yet", {
              variant: "error",
            });

            setIsLoading(false);
            return;
          }
        }

        if (
          appStore.chainId !==
          parseInt(process.env["CHAIN_ID"] ?? errorMessageEnv, 16)
        ) {
          enqueueSnackbar("Please connect to Metis Mainnet", {
            variant: "error",
          });
          setIsLoading(false);
          return;
        }

        if (mapStore.selectedTilesTypes[0] === TileTypes.Premium) {
          const proofs = await getProofs(mapStore.selectedTiles);
          await appStore.buyPremiumTiles(
            mapStore.selectedTiles,
            proofs,
            appStore.account
          );
        } else {
          await appStore.buyTiles(mapStore.selectedTiles, appStore.account);
        }

        await appStore.updateBalance();

        enqueueSnackbar(
          `You successfully bought ${mapStore.selectedTiles.length} lands!`,
          { variant: "success" }
        );

        mapStore.purchasedTiles = mapStore.selectedTiles;
        mapStore.selectedTiles = [];
        mapStore.selectedTilesTypes = [];

        setTimeout(() => {
          window.location.reload();
        }, 2_000);
      } catch (e) {
        console.error(e);
        // alert(e.message)
        enqueueSnackbar("Something went wrong.", { variant: "error" });
      }

      setIsLoading(false);
    }

    return (
      <div>
        <ViewMyLands
          open={myLandsVisible}
          handleClose={() => setIsMyLandsVisible(false)}
          appStore={appStore}
          mapStore={mapStore}
        />

        <ViewAllLands
          open={allLandsVisible}
          handleClose={() => {
            setIsAllLandsVisible(false);
          }}
          mapStore={mapStore}
        />

        <ViewAllLands
          open={premiumLandsVisible}
          handleClose={() => {
            setPremiumLandsVisible(false);
          }}
          premiumLandsVisible={premiumLandsVisible}
          mapStore={mapStore}
        />
        {!isMobile && (
          <>
            <div className={styles["gray-block"]}>
              <h2 className={styles["title"]}>Navigation</h2>

              <Grid container spacing={1}>
                <Grid item>
                  <button
                    className={styles["button"]}
                    onClick={() => setIsAllLandsVisible((s) => !s)}
                  >
                    <Grid
                      container
                      alignItems="center"
                      className={styles["premium-button-content"]}
                    >
                      <Grid item>
                        <img
                          src={AllLands}
                          alt="All lands"
                          style={{ paddingRight: "8px" }}
                        />
                      </Grid>
                      <Grid item> All lands</Grid>
                    </Grid>
                  </button>
                </Grid>
                {appStore.account && (
                  <Grid item>
                    <button
                      disabled={!appStore.account}
                      className={styles["button"]}
                      onClick={() => setIsMyLandsVisible((s) => !s)}
                    >
                      <Grid
                        container
                        alignItems="center"
                        className={styles["premium-button-content"]}
                      >
                        <Grid item>
                          <img
                            src={MyLands}
                            alt="My lands"
                            style={{ paddingRight: "8px" }}
                          />
                        </Grid>
                        <Grid item> My lands</Grid>
                      </Grid>
                    </button>
                  </Grid>
                )}
                <Grid item>
                  <button
                    className={`${styles["button"]} ${styles["premium-button"]}`}
                    onClick={() => {
                      setPremiumLandsVisible((s) => !s);
                    }}
                  >
                    <Grid
                      container
                      alignItems="center"
                      className={styles["premium-button-content"]}
                    >
                      <Grid item>
                        <img
                          src={Crown}
                          alt="Premium"
                          style={{ paddingRight: "8px" }}
                        />
                      </Grid>
                      <Grid item> View premium</Grid>
                    </Grid>
                  </button>
                </Grid>
              </Grid>
            </div>
            {/* <div className={styles["whats-here-block"]}> */}
            {/* <h2 className={styles["title"]}>Info</h2> */}
            {/*{getValue(StorageKey.WL_PROOFS) && getValue(StorageKey.WL_PROOFS)?.split(',')[0] !== '' && appStore.account ? (*/}
            {/*    <p>*/}
            {/*        <b>*/}
            {/*            <font color={'green'}>Congratulations! Your address is whitelisted for the early minting</font>*/}
            {/*        </b>*/}
            {/*    </p>*/}
            {/*) : (*/}
            {/*    <p>*/}
            {/*        <b>*/}
            {/*            <font color={'red'}>Your address is NOT whitelisted for the early minting!</font>*/}
            {/*        </b>*/}
            {/*    </p>*/}
            {/*)}*/}
            {/* <a href={"https://metisearth.gitbook.io/metisearth/protocol/tutorial"} target={"_blank"}>How to mint</a> */}
            {/* </div> */}
            <div className={styles["whats-here-block"]}>
              <h2 style={{ fontWeight: 500, marginRight: 17 }}>Search:</h2>
              <AddressSearch mapStore={mapStore} />
            </div>
            <div className={styles["whats-here-block"]}>
              <h2>Checkout</h2>
              <div>
                <TilesCost mapStore={mapStore} />
                <p>
                  Total:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    <Cost mapStore={mapStore} />
                  </span>
                </p>
                <p>
                  Per land: <PerLandCost mapStore={mapStore} />
                </p>
                <p>
                  Selected lands:{" "}
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    <span
                      style={{
                        color:
                          mapStore.selectedTiles.length === 10
                            ? "red"
                            : undefined,
                      }}
                    >
                      {mapStore.selectedTiles.length}
                    </span>{" "}
                    / 10
                  </span>
                </p>
              </div>
            </div>
          </>
        )}
        {isMobile && (
          <>
            <>
              <div className={styles["gray-block"]}>
                <h2 className={styles["title"]}>Navigation</h2>

                <Grid container spacing={1}>
                  <Grid item>
                    <button
                      className={styles["button"]}
                      onClick={() => setIsAllLandsVisible((s) => !s)}
                    >
                      <Grid
                        container
                        alignItems="center"
                        className={styles["premium-button-content"]}
                      >
                        <Grid item>
                          <img
                            src={AllLands}
                            alt="All lands"
                            style={{ paddingRight: "8px" }}
                          />
                        </Grid>
                        <Grid item> All lands</Grid>
                      </Grid>
                    </button>
                  </Grid>
                  {appStore.account && (
                    <Grid item>
                      <button
                        disabled={!appStore.account}
                        className={styles["button"]}
                        onClick={() => setIsMyLandsVisible((s) => !s)}
                      >
                        <Grid
                          container
                          alignItems="center"
                          className={styles["premium-button-content"]}
                        >
                          <Grid item>
                            <img
                              src={MyLands}
                              alt="My lands"
                              style={{ paddingRight: "8px" }}
                            />
                          </Grid>
                          <Grid item> My lands</Grid>
                        </Grid>
                      </button>
                    </Grid>
                  )}

                  <Grid item>
                    <button
                      className={`${styles["button"]} ${styles["premium-button"]}`}
                      onClick={() => {
                        setPremiumLandsVisible((s) => !s);
                      }}
                    >
                      <Grid
                        container
                        alignItems="center"
                        className={styles["premium-button-content"]}
                      >
                        <Grid item>
                          <img
                            src={Crown}
                            alt="Premium"
                            style={{ paddingRight: "8px" }}
                          />
                        </Grid>
                        <Grid item> View premium</Grid>
                      </Grid>
                    </button>
                  </Grid>
                </Grid>
              </div>
              {/* <div className={styles['whats-here-block']}> */}
              {/* <h2 className={styles["title"]}>Info</h2> */}
              {/*{getValue(StorageKey.WL_PROOFS) && getValue(StorageKey.WL_PROOFS)?.split(',')[0] !== '' && appStore.account ? (*/}
              {/*    <p>*/}
              {/*        <b>*/}
              {/*            <font color={'green'}>Congratulations! Your address is whitelisted for the early minting</font>*/}
              {/*        </b>*/}
              {/*    </p>*/}
              {/*) : (*/}
              {/*    <p>*/}
              {/*        <b>*/}
              {/*            <font color={'red'}>Your address is NOT whitelisted for the early minting!</font>*/}
              {/*        </b>*/}
              {/*    </p>*/}
              {/*)}*/}
              {/* <a href={"https://metisearth.gitbook.io/metisearth/protocol/tutorial"} target={"_blank"}>How to mint</a> */}
              {/* </div> */}
              <div className={styles["whats-here-block"]}>
                <h2>Checkout</h2>
                <div>
                  <TilesCost mapStore={mapStore} />
                  <p>
                    Total:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      <Cost mapStore={mapStore} />
                    </span>
                  </p>
                  <p>
                    Per land: <PerLandCost mapStore={mapStore} />
                  </p>
                  <p>
                    Selected lands:{" "}
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      <span
                        style={{
                          color:
                            mapStore.selectedTiles.length === 10
                              ? "red"
                              : undefined,
                        }}
                      >
                        {mapStore.selectedTiles.length}
                      </span>{" "}
                      / 10
                    </span>
                  </p>
                </div>
              </div>
            </>
          </>
        )}

        {/*{mapStore.selectedTiles.length > 0 && (*/}
        <div className={styles["owner-block"]}>
          <div>
            <h2>Owner</h2>
            <h2>None, you can buy it now!</h2>
          </div>

          {appStore.account ? (
            <button
              className={styles["buy-btn"]}
              onClick={() => {
                if (mapStore.selectedTiles.length === 0) {
                  enqueueSnackbar("Please select up to 10 lands", {
                    variant: "warning",
                  });

                  return;
                }

                setIsLoading(true);
                void buy();
              }}
              disabled={isLoading}
            >
              {isLoading ? (
                <CircularProgress
                  style={{
                    width: 25,
                    height: 25,
                    strokeWidth: 4,
                    color: "black",
                  }}
                />
              ) : null}

              <h2>Mint</h2>
            </button>
          ) : (
            <button className={styles["buy-btn"]} onClick={appStore.connect}>
              <ButtonWallet cl={styles["buy-btn"]} onAdd={appStore.connect} />
            </button>
          )}
        </div>
        {/*)}*/}
        {/* <Rewards appStore={appStore} /> */}
      </div>
    );
  }
);

const PerLandCost = observer(({ mapStore }: { mapStore: MapStore }) => {
  if (!mapStore.availableToBuy) {
    return null;
  }

  if (mapStore.firsTileType === TileTypes.Premium) {
    return <span style={{ fontWeight: "bold" }}>{premiumTileCost} ETH</span>;
  }
  return <span style={{ fontWeight: "bold" }}>{tileCost} ETH</span>;
});

const Cost = observer(({ mapStore }: { mapStore: MapStore }) => {
  if (!mapStore.availableToBuy) {
    return null;
  }

  if (mapStore.firsTileType === TileTypes.Premium) {
    return (
      <>{(mapStore.selectedTiles.length * premiumTileCost).toFixed(5)} ETH</>
    );
  }
  return <>{(mapStore.selectedTiles.length * tileCost).toFixed(5)} ETH</>;
});
