.landing {
  display: flex;

  flex-direction: column;
}

/* styles for header */

.hero {
  padding-top: 5rem;
  display: flex;
  flex-direction: column;

  align-items: center;

  position: relative;

  height: calc(70vw * 0.5659);
  max-height: 775px;

  background-size: cover;
  background-repeat: no-repeat;
  clip-path: polygon(0 0, 100% 0%, 100% 80%, 50% 100%, 0 80%);
  background: var(
    --1,
    linear-gradient(265deg, #11c9e3 -7.11%, #ba15f4 103.55%)
  );
  height: 380px;
}

.hero > h1 {
  margin-bottom: 20px;

  font-size: 72px;

  z-index: 1;
  color: white;
}

.blocks {
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-around;

  width: 100%;

  z-index: 1;
}

.description-block {
  padding: 18px 30px;
  /* background-color: rgba(49, 49, 49, 1); */

  color: white;
  border-radius: 7px;
}

.description-block > h2 {
  font-size: 40px;
  font-weight: 500;

  margin: 0;
}

.description-block > h3 {
  font-size: 30px;
  font-weight: 500;

  margin: 12px 0 0;
}

.timer-block {
  padding: 18px;
  background-color: rgba(49, 49, 49, 1);

  color: white;
  border-radius: 7px;

  min-height: 138px;
  box-sizing: border-box;

  display: flex;

  justify-content: center;
  align-items: center;

  min-width: 400px;
}

.timer-block > h2 {
  font-weight: 400;

  font-size: 32px;
  margin: 0;
}

.timer-block > h3 {
  font-size: 40px;
  margin: 12px auto 0;

  font-weight: 700;

  width: fit-content;
}

.hero-icon {
  z-index: 1;

  left: 0;
  bottom: 100px;

  width: 100%;
}

.description-section {
  /* background-color: rgba(35, 35, 35, 1); */
  color: #000;

  display: flex;

  align-items: center;
  justify-content: center;
}

.description-section > h2 {
  text-align: center;

  font-size: 32px;
  font-weight: 500;
}

.description-section > h2 > span:first-child {
  background: var(
    --1,
    linear-gradient(265deg, #11c9e3 -7.11%, #ba15f4 103.55%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-left: 0;
}

.description-section > h2 > span {
  font-size: 40px;
  font-weight: 700;
  border-radius: 41px;
  background: var(
    --1,
    linear-gradient(265deg, #11c9e3 -7.11%, #ba15f4 103.55%)
  );
  display: inline-flex;
  padding: 16px 48px 14px 48px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #fff;
}

.btn-nav {
  font-size: 40px;
  font-weight: 700;
  border-radius: 41px;
  background: var(
    --1,
    linear-gradient(265deg, #11c9e3 -7.11%, #ba15f4 103.55%)
  );
  display: inline-flex;
  padding: 16px 48px 14px 48px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #fff;
  cursor: pointer;
  border: none;
}

.project-info-section {
  display: grid;

  grid-template-columns: 1fr 1fr;

  /* background-color: rgba(49, 49, 49, 1); */
  color: #000;

  padding: 30px;

  position: relative;
}

.project-info-block {
  /* color: white; */
  color: #000;
  font-weight: 400;
  /* background-color: rgba(35, 35, 35, 1); */

  margin-left: 5%;
  margin-right: 5%;

  margin-bottom: 5%;

  z-index: 1;
  border: double 1px transparent;
  border-radius: 30px;
  /* background-image: linear-gradient(white, white),
    linear-gradient(to right, green, gold); */
  background-image: linear-gradient(white, white),
    linear-gradient(to right, #ba15f4, #11c9e3);
  background-origin: border-box;
  background-clip: content-box, border-box;

  /* display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center; */
  padding: 2px;
  /* position: relative; */
}

.project-info-block > div {
  /* background-color: rgba(62, 62, 62, 1); */
  color: #000;
  display: flex;
  flex-direction: row;

  padding: 0 36px;

  align-items: center;
}

.project-info-block > div > h2 {
  font-weight: 500;
  background: var(
    --2,
    linear-gradient(277deg, #11c9e3 -24.28%, #ba15f4 178.4%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.project-info-block > p {
  margin-top: 0;
  padding: 0 36px;
}

.project-info-block > p > a {
  text-decoration: none;
  color: #15a2ab;
}

.project-info-block > div > svg {
  margin-right: 12px;
}

.project-info-section-icon {
  position: absolute;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 100%;
}

.statistics-section {
  background: #313131;
  box-shadow: inset 0px 4px 60px 53px rgba(0, 0, 0, 0.25);

  color: white;
}

.statistics-section > div {
  width: 100%;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.stats-block {
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
}

.stats-block > h4 {
  font-size: 32px;
  font-weight: 300;

  margin: 0 0 9px;
}

.stats-block > p {
  font-size: 40px;
  font-weight: 700;

  margin-top: 0;
}

.stats-block > div {
  margin-bottom: 9px;
}

.stats-block > div > h4 {
  font-size: 32px;
  font-weight: 300;

  margin: 0;
}

.stats-block > div > svg {
  color: rgba(237, 155, 59, 1);
  margin-right: 12px;
}

.statistics-section-title {
  font-weight: 700;
  font-size: 40px;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
}

.leaderboards-section {
  background-color: rgba(229, 227, 227, 1);

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
}

.leaderboards-section > h2 {
  font-size: 40px;
}

.leaderboards-section > h3 {
  font-size: 32px;
}

.roadmap-section {
  background-color: rgba(49, 49, 49, 1);

  position: relative;
  overflow: hidden;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
}

.roadmap-icons {
  position: absolute;

  width: 100%;

  left: 50%;
  top: 50%;

  transform: translate(-50%, -60%);
}

.roadmap-section > h2 {
  color: white;
  font-size: 40px;
}

.roadmap-step {
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;

  color: white;

  max-width: 1300px;
  height: 246px;

  z-index: 1;

  margin-bottom: 52px;
}

.roadmap-step-title {
  padding: 0 64px;
  background-color: rgba(62, 62, 62, 1);

  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.roadmap-step-title > h3 {
  font-size: 40px;

  white-space: nowrap;
}

.roadmap-step-title > h3 > span {
  font-size: 32px;
  font-weight: 300;
}

.roadmap-step-description {
  background-color: rgba(35, 35, 35, 1);
  padding: 0 64px;

  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.roadmap-step-description > p {
  font-size: 24px;
}

.roadmap-step:nth-child(2n) > .roadmap-step-description > p {
  text-align: right;
}

@media screen and (max-width: 1370px) {
  .blocks {
    flex-direction: column;

    width: auto;

    max-width: 95%;
  }

  .hero > h1 {
    margin-bottom: 20px;
  }

  .timer-block {
    margin-top: 30px;

    min-width: 0 !important;

    width: 100%;

    box-sizing: border-box;
  }

  .hero {
    /* height: auto; */
    height: 450px;
  }

  .statistics-section > div {
    display: flex;
    flex-direction: column;
  }

  .roadmap-step {
    max-width: 95%;
  }

  .roadmap-step-description {
    padding: 0 32px;
  }

  .roadmap-step-title {
    padding: 0 32px;
  }

  .roadmap-icons > img:last-child {
    margin-top: 350px;
  }
}

@media screen and (max-width: 1000px) {
  .project-info-section {
    display: flex;
    flex-direction: column;

    padding: 30px 0;
  }

  .roadmap-step {
    flex-direction: column;
    height: auto;
  }

  .roadmap-step:nth-child(2n) {
    flex-direction: column-reverse;
  }

  .roadmap-step:nth-child(2n) > .roadmap-step-description > p {
    text-align: left;
  }

  .roadmap-step-title {
    width: 95%;
    height: auto;
    box-sizing: border-box;

    padding: 16px 32px;

    text-align: center;
  }

  .roadmap-step-description {
    width: 95%;
    height: auto;
    box-sizing: border-box;

    padding: 16px 32px;

    text-align: center;
  }
}

@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .project-info-section {
    display: flex;
    flex-direction: column;

    padding: 30px 0;
  }

  .roadmap-step {
    flex-direction: column;
    height: auto;
  }

  .roadmap-step:nth-child(2n) {
    flex-direction: column-reverse;
  }

  .roadmap-step:nth-child(2n) > .roadmap-step-description > p {
    text-align: left;
  }

  .roadmap-step-title {
    width: 95%;
    height: auto;
    box-sizing: border-box;

    padding: 16px 32px;

    text-align: center;
  }

  .roadmap-step-description {
    width: 95%;
    height: auto;
    box-sizing: border-box;

    padding: 16px 32px;

    text-align: center;
  }

  .description-block > h2 {
    font-size: 35px;
  }
  .description-block > h3 {
    font-size: 28px;
  }
}

@media screen and (max-width: 948px) {
  .hero {
    height: 550px;
    text-align: center;
  }
}

@media screen and (max-width: 631px) {
  .hero {
    height: 570px;
  }

  .description-block > h2 {
    font-size: 35px;
  }

  .description-block > h3 {
    font-size: 28px;
  }
}

@media screen and (max-width: 450px) {
  .hero {
    height: 650px;
  }
}

@media screen and (max-width: 500px) {
  .hero > h1 {
    font-size: 45px;
    text-align: center;
  }
}

@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .hero {
    height: 630px;
  }

  .hero > h1 {
    font-size: 45px;
    text-align: center;
  }
}
