import Blockies from "react-blockies";
import { Popup, Rectangle, SVGOverlay } from "react-leaflet-metisearth";
import {
  getTileColor,
  getTileFillOpacity,
  getTileWeight,
  Tile,
} from "../../utils/mapUtils";
import { cutAddress } from "../../utils/format";
import React from "react";
import Flag from "react-world-flags";
import { observer } from "mobx-react-lite";
import { mapStore, MapStore } from "../../store/mapStore";
import crown from "../../img/crown.svg";
interface TileRectanglesProps {
  tiles: Tile[];
  mapStore: MapStore;
}

export const TileRectangles = observer((props: TileRectanglesProps) => {
  // const isNeedToShowPopup = (tile: Tile) => {
  //   if (tile.isPremium) {
  //     return tile.isMinted;
  //   }

  //   return tile.owner;
  // };

  return (
    <>
      {props.tiles.map((tile) => (
        <Rectangle
          key={tile.index}
          opacity={0.4}
          fillOpacity={getTileFillOpacity(tile)}
          weight={getTileWeight(tile)}
          bounds={tile.bounds}
          color={getTileColor(tile, props.mapStore.displayGrid)}
        >
          {tile.isPremium && (
            <SVGOverlay attributes={{ stroke: "#ED9B3B" }} bounds={tile.bounds}>
              <image href={crown} width="20%" height="20%" x="40%" y="40%" />
            </SVGOverlay>
          )}
          {/* {isNeedToShowPopup(tile) && (
            <Popup
              onClose={() => (props.mapStore.popupOpened = false)}
              onOpen={() => (props.mapStore.popupOpened = true)}
            >
              <div>
                <div>
                  <Blockies size={13} seed={tile.owner} />
                </div>
                <p>
                  <b>Owner: </b>
                  <b>
                    <a
                      target="_blank"
                      rel="noopener"
                      href={`https://andromeda-explorer.metis.io/address/${tile.owner}`}
                    >
                      {cutAddress(tile.owner)}
                    </a>
                  </b>
                </p>
                <p>
                  <b>Land ID:</b> {tile.index}
                </p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "fit-content",
                  }}
                >
                  <b>
                    <p style={{ margin: 0 }}>Country:</p>
                  </b>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Flag
                      code={tile.country}
                      style={{ width: 20, height: 20, margin: "0 6px" }}
                    />

                    <p style={{ margin: 0 }}>{tile.countryName}</p>
                  </div>
                </div>
              </div>
            </Popup>
          )} */}
        </Rectangle>
      ))}
    </>
  );
});
