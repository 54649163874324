import React, { useEffect } from "react";
import { Route, BrowserRouter } from "react-router-dom";
import { Routes } from "react-router";
import { Landing } from "./pages/Landing/Landing";
import { Navbar } from "./components/Navbar/Navbar";
import { Explore } from "./pages/Explore/Explore";
import { AppStore } from "./store/appStore";
import { MapStore } from "./store/mapStore";
import Map3d from "./components/Map3d/Map3d";
import Help from "./components/Help/Help";
import {
  createWeb3Modal,
  defaultConfig,
  useWeb3ModalSigner,
} from "@web3modal/ethers5/react";
// 1. Get projectId
const projectId = "1bfbfbd54dfc96f54b35a2c09c1d4152";

// 2. Set chains
const mainnet = {
  chainId: 8453,
  name: "Base",
  currency: "ETH",
  explorerUrl: "https://basescan.org/",
  rpcUrl: "https://base.llamarpc.com",
};

// 3. Create modal
const metadata = {
  name: "NovaEarth",
  description: "NovaEarth NFT",
  url: "https://safe-earth.vercel.app/",
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const modal = createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [mainnet],
  projectId,
});

function App({
  appStore,
  mapStore,
}: {
  appStore: AppStore;
  mapStore: MapStore;
}) {
  const { walletProvider } = useWeb3ModalSigner();
  useEffect(() => {
    // void appStore.connect().then(() => appStore.isApprovalForAll());
  }, []);

  useEffect(() => {
    if (walletProvider?.provider)
      appStore.setProvider(walletProvider?.provider);

    void appStore.connect();
  }, [walletProvider?.provider]);

  return (
    <BrowserRouter>
      <Navbar appStore={appStore} mapStore={mapStore} />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/explore" element={<Explore />} />
        <Route path="/map3d" element={<Map3d />} />
        <Route path="/help" element={<Help />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
