import {observer} from "mobx-react-lite";
import {MapStore} from "../../../../store/mapStore";
import {Search} from "../../../../components/Search/Search";
import {useState} from "react";
import {useQuery} from "react-query";
import {Place, searchPlaces} from "../../../../api/searchPlaces";

import styles from './styles.module.css'
import {CircularProgress} from "@mui/material";

export const AddressSearch = observer(({ mapStore } : { mapStore: MapStore }) => {
    const [query, setQuery] = useState('')

    const { isLoading, error, data } = useQuery(
        ['map-objects', query],
        () => searchPlaces(query),
        {
            enabled: !!query.trim()
        }
    )

    return (
        <Search onQueryChanged={setQuery} placeholder="Find any place: New York; Chicago; etc">
            <SearchResults
                query={query}
                isLoading={isLoading}
                results={data}
                selectPlace={(place) => {
                    mapStore.map?.fitBounds([
                        [place.boundingBox[0], place.boundingBox[2]],
                        [place.boundingBox[1], place.boundingBox[3]]
                    ])
                }}
            />
        </Search>
    )
})

interface SearchResultProps {
    query: string
    results?: Place[]
    isLoading: boolean

    selectPlace: (place: Place) => void
}

const SearchResults = ({ query, results, isLoading, selectPlace } : SearchResultProps) => {
    if (isLoading) {
        return (
            <CircularProgress
                style={{
                    width: 50,
                    height: 50,
                    strokeWidth: 4,
                    color: '#00a8ff'
                }}
            />
        )
    }

    if (results && results.length) {
        return (
            <>
                {results.map(result => (
                    <button className={styles['result-button']} onClick={() => selectPlace(result)}>
                        {result.displayName}
                    </button>
                ))}
            </>
        )
    }

    if (!query.trim()) {
        return <p>Type your query</p>
    } else {
        return <p>No results for query: {query}</p>
    }
}
