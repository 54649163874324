import React, { useEffect, useState } from "react";
import { cutAddress } from "../utils/format";
import Flag from "react-world-flags";
import ReactDOMServer from "react-dom/server";
import blockies from "blockies";
import { popup } from "leaflet";
import { MapStore } from "../store/mapStore";
import { tileToCoords } from "../utils/mapUtils";

const useCustomPopup = (mapStore: MapStore, ready: boolean) => {
  useEffect(() => {
    if (!ready) {
      return;
    }
    if (mapStore.popupToOpen1 && mapStore.map) {
      const { lat, lng } = tileToCoords(mapStore.popupToOpen1.coordinates);
      const x1 = mapStore.popupToOpen1.coordinates.x + 1;
      const y1 = mapStore.popupToOpen1.coordinates.y + 1;
      const { lat: lat1, lng: lng1 } = tileToCoords({ x: x1, y: y1 });

      mapStore.map.on("popupopen", function () {
        const icon = blockies({
          seed: mapStore.popupToOpen1.owner,
          size: 13,
        });
        if (
          document.getElementById(
            `${mapStore.popupToOpen1.tokenId || mapStore.popupToOpen1.index}`,
          )
        ) {
          document
            .getElementById(
              `${mapStore.popupToOpen1.tokenId || mapStore.popupToOpen1.index}`,
            )
            .appendChild(icon);
        }

        mapStore.map.off("popupopen");
      });

      popup()
        .setLatLng([(lat + lat1) / 2, (lng + lng1) / 2])
        .setContent(
          ReactDOMServer.renderToString(
            <PopupContent
              popupToOpen={mapStore.popupToOpen1}
              id={mapStore.popupToOpen1.tokenId || mapStore.popupToOpen1.index}
            />,
          ),
        )
        .openOn(mapStore.map);
    }
  }, [mapStore.popupToOpen1, ready]);
};

const PopupContent = (props: any) => {
  const { popupToOpen, id } = props;

  return (
    <div>
      <div id={`${id}`}></div>
      <p>
        <b>Owner: </b>
        <b>
          <a
            target="_blank"
            rel="noopener"
            href={`https://base.blockscout.com/address/${popupToOpen.owner}`}
          >
            {cutAddress(popupToOpen.owner)}
          </a>
        </b>
      </p>
      <p>
        <b>Land ID:</b> {popupToOpen.tokenId || popupToOpen.index}
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          width: "fit-content",
        }}
      >
        <b>
          <p style={{ margin: 0 }}>Country:</p>
        </b>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Flag
            code={popupToOpen.country}
            style={{ width: 20, height: 20, margin: "0 6px" }}
          />

          <p style={{ margin: 0 }}>{popupToOpen.countryName}</p>
        </div>
      </div>
    </div>
  );
};

export { useCustomPopup };
