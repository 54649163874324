import React, { useEffect, useRef, useState } from "react";

import { observer } from "mobx-react-lite";
import { ViewLands } from "./ViewLands";
import { useQuery } from "react-query";
import { getLands } from "../../api/parser";
import { MapStore } from "../../store/mapStore";
import { useNavigate } from "react-router-dom";
import { NOT_SELECTED_COUNTRY_CODE } from "../../const/const";

interface ViewMyLands {
  open: boolean;
  handleClose: () => void;

  mapStore: MapStore;
  premiumLandsVisible?: boolean;
}

export const ViewAllLands = observer(
  (props: ViewMyLands): React.ReactElement => {
    const [page, setPage] = useState(1);
    const [isFiltersApplied, setIsFiltersApplied] = useState(false);
    const [isPremiumLands, setIsPremiumLands] = useState(false);
    const [isMintedLands, setIsMintedLands] = useState(false);
    const [country, setCountry] = useState<string>("");
    const [inputText, setInputText] = useState("");
    const [query, setQuery] = useState("");
    const timeoutId = useRef<number>(0);
    const navigate = useNavigate();

    const togglePremiumLands = () => {
      if (isPremiumLands) {
        setIsMintedLands(false);
      }
      setIsPremiumLands(!isPremiumLands);
    };

    const toggleMintedLands = () => {
      setIsMintedLands(!isMintedLands);
    };

    const selectCountry = (countryCode: string) => {
        if (countryCode === NOT_SELECTED_COUNTRY_CODE ? "" : countryCode !== country) {
            setPage(1);
        }

      countryCode === NOT_SELECTED_COUNTRY_CODE
        ? setCountry("")
        : setCountry(countryCode);
    };

    const { isLoading, error, data, refetch } = useQuery(
      ["get-user-lands", query, props.open, page],
      () => getLands(page, query, isPremiumLands, isMintedLands, country),
      {
        enabled: false,
      },
    );

    const cleanUp = () => {
      setIsPremiumLands(false);
      setIsMintedLands(false);
      setCountry("");
      setInputText("");
      setQuery("");
      setPage(1);
      clearTimeout(timeoutId.current);
    };

    useEffect(() => {
      if (!props.open) {
        cleanUp();
      }

      return () => {
        cleanUp();
      };
    }, [props.open]);

    useEffect(() => {
      if (props.open) {
        setIsPremiumLands(!!props.premiumLandsVisible);
        setIsFiltersApplied(true);
      } else {
        setIsFiltersApplied(false);
      }

      return () => {
        setIsFiltersApplied(false);
      };
    }, [props.premiumLandsVisible, props.open]);

    useEffect(() => {
      if (isFiltersApplied) {
        refetch();
      }
    }, [
      isPremiumLands,
      isMintedLands,
      country,
      isFiltersApplied,
      query,
      props.open,
      page,
    ]);

    function handleSearch(query: string) {
      clearTimeout(timeoutId.current);
      setInputText(query);

      timeoutId.current = setTimeout(() => {
        setQuery(query);
        setPage(1);
      }, 200) as unknown as number;
    }

    function cutQuery(query: string) {
      if (query.length > 7) {
        return query.substring(0, 7) + "...";
      }

      return query;
    }

    return (
      <ViewLands
        open={props.open}
        handleClose={props.handleClose}
        lands={data?.tiles}
        isLoading={isLoading}
        error={Boolean(error)}
        title={
          query
            ? `'${cutQuery(query)}' lands`
            : isPremiumLands
            ? "Premium lands"
            : "All lands"
        }
        searchEnabled
        query={inputText}
        onSearch={handleSearch}
        currentPage={page}
        pages={data?.pages ?? 1}
        onPageChanged={(p) => setPage(p)}
        isPremiumLands={isPremiumLands}
        togglePremiumLands={togglePremiumLands}
        isMintedLands={isMintedLands}
        toggleMintedLands={toggleMintedLands}
        onView={(coords) => {
          props.mapStore.map?.closePopup();
          props.mapStore.popupBound = null;
          props.mapStore.popupToOpen = null;
          navigate(`/explore?x=${coords.x}&y=${coords.y}`);
          props.handleClose();
        }}
        selectCountry={selectCountry}
      />
    );
  },
);
