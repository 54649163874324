import React, { useState } from "react";
import styles from "./styles.module.css";
import logo from "../../img/nova_logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDiscord,
  faGithub,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faBook, faHandsHelping } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { faBinoculars, faMap } from "@fortawesome/free-solid-svg-icons";
import { observer } from "mobx-react-lite";
import { cutAddress } from "../../utils/format";
import { Grid, IconButton, Drawer } from "@mui/material";
import Crown from "../../img/crown.svg";
import metisLogo from "../../img/metis-logo.png";
import { AppStore } from "../../store/appStore";
import BigNumber from "bignumber.js";
import { metamaskEnabled } from "../../const/const";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuIcon from "@mui/icons-material/Menu";
import { ViewAllLands } from "../ViewLands/ViewAllLands";
import { MapStore } from "../../store/mapStore";
import { useNavigate } from "react-router-dom";
import { LandsModal } from "../Modals/LandsModal/LandsModal";
import ButtonWallet from "../WalletConnect/ButtonWallet";

export const Navbar = observer(
  ({
    appStore,
    mapStore,
  }: {
    appStore: AppStore;
    mapStore: MapStore;
  }): React.ReactElement => {
    const navigate = useNavigate();
    const [premiumLandsVisible, setPremiumLandsVisible] = useState(false);
    const [marketplace, setMarketplace] = useState(false);
    const isSmallScreen = useMediaQuery("(max-width:1200px)");
    const [open, setOpen] = useState(false);

    const toggleDrawer = () => {
      setOpen(!open);
    };

    return (
      <>
        <ViewAllLands
          open={premiumLandsVisible}
          handleClose={() => {
            setPremiumLandsVisible(false);
          }}
          premiumLandsVisible={premiumLandsVisible}
          mapStore={mapStore}
        />
        {/* <LandsModal
          open={marketplace}
          handleClose={() => setMarketplace(false)}
          mapStore={mapStore}
          currentModal="Marketplace"
        /> */}
        <div className={styles["header"]}>
          <Link to="/" style={{ textDecoration: "none" }}>
            <div className={styles["project-info"]}>
              <img className={styles["logo"]} src={logo} alt="logo" />

              <p className={styles["project-title"]}>NovaEarth</p>
            </div>
          </Link>

          <div className={styles["controls"]}>
            {/* <div className={styles["socials"]}>
              <a
                href="https://github.com/metisEarth-com/"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faGithub} size="2x" />
              </a>
              <a
                href="https://twitter.com/MetisEarth"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faTwitter} size="2x" />
              </a>
              <a
                href="https://discord.gg/bqy7qjhdBk"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faDiscord} size="2x" />
              </a>
              <a
                href="https://metisearth.gitbook.io/metisearth/"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faBook} size="2x" />
              </a>
            </div> */}

            {/* {isSmallScreen ? (
              <>
                <button
                  className={`${styles["button"]} ${styles["premium_button"]}`}
                  onClick={() => {
                    navigate("/explore");
                    setPremiumLandsVisible((s) => !s);
                  }}
                >
                  <Grid container alignItems="center" className={}>
                    <Grid item>
                      <img
                        src={Crown}
                        alt="Premium"
                        style={{ paddingRight: "8px" }}
                      />
                    </Grid>
                    <Grid item>Premium</Grid>
                  </Grid>
                </button>
              </>
            ) : ( */}
            {!isSmallScreen && (
              <>
                {appStore.account ? (
                  <div className={styles["account-block"]}>
                    <div className={styles["balance"]}>
                      <img src={metisLogo} alt="metis logo" />

                      <p>
                        {new BigNumber(appStore.balance)
                          .times(1e-18)
                          .toFixed(5)}{" "}
                        <span className={styles["currency"]}>ETH</span>
                      </p>
                    </div>

                    <div className={styles["address"]}>
                      {cutAddress(appStore.account)}
                    </div>
                  </div>
                ) : (
                  <ButtonWallet onAdd={appStore.connect.bind(appStore)} />
                )}
              </>
            )}
            {/* )} */}

            {/* <Link to="/explore" style={{ marginLeft: "21px" }}>
              <button className={styles["button"]}>
                <FontAwesomeIcon
                  icon={faBinoculars}
                  className={styles["icon"] as string}
                />
                Explore
              </button>
            </Link> */}
            <Link to="/map3d" style={{ marginLeft: "0px" }}>
              <button className={styles["button"]}>
                <FontAwesomeIcon
                  icon={faMap}
                  className={styles["icon"] as string}
                />
                World
              </button>
            </Link>

            <Link to="/help" style={{ marginLeft: "0px" }}>
              <button className={`${styles["button"]} ${styles["btn-help"]}`}>
                How-to
              </button>
            </Link>
            {/* {!isSmallScreen && (
              <div style={{ marginRight: "21px" }}>
                <button
                  onClick={() => setMarketplace(true)}
                  className={styles["button"]}
                >
                  Marketplace
                </button>
              </div>
            )} */}
            <button
              className={`${styles["button"]} ${styles["premium_button"]} ${styles["premium_button_desktop"]}`}
              onClick={() => {
                navigate("/explore");
                setPremiumLandsVisible((s) => !s);
              }}
            >
              <Grid container alignItems="center">
                <Grid item>
                  <img
                    src={Crown}
                    alt="Premium"
                    style={{ paddingRight: "8px" }}
                  />
                </Grid>
                <Grid item>Premium</Grid>
              </Grid>
            </button>
          </div>
          {isSmallScreen && (
            <IconButton
              className={styles["icn-btn"]}
              style={{ background: "#FFF", borderRadius: "12px" }}
            >
              <MenuIcon style={{ color: "#11C9E3" }} onClick={toggleDrawer} />
            </IconButton>
          )}
          {
            <Drawer
              open={open}
              onClose={toggleDrawer}
              anchor="right"
              PaperProps={{
                style: {
                  // backgroundColor: "rgba(49, 49, 49, 1)",
                  backgroundColor: "#0094efe8",
                  color: "#fff",
                  padding: "20px",
                  width: "200px",
                },
                className: styles["drawer-menu"],
              }}
            >
              <div style={{ marginBottom: "10px" }}>
                <div style={{ fontSize: "20px", marginBottom: "10px" }}>
                  Account
                </div>
                {appStore.account ? (
                  <div className={styles["account-block"]}>
                    <div className={styles["balance"]}>
                      <img src={metisLogo} alt="metis logo" />

                      <p>
                        {new BigNumber(appStore.balance)
                          .times(1e-18)
                          .toFixed(5)}{" "}
                        <span className={styles["currency"]}>ETH</span>
                      </p>
                    </div>

                    <div className={styles["address"]}>
                      {cutAddress(appStore.account)}
                    </div>
                  </div>
                ) : (
                  <ButtonWallet onAdd={appStore.connect.bind(appStore)} />
                )}
              </div>
              {/* <div style={{ fontSize: "20px", marginBottom: "10px" }}>
                Socials
              </div>
              <div>
                <a
                  href="https://github.com/metisEarth-com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Grid container spacing={2} alignItems="center">
                    <Grid item sm={4} xs={4} style={{ textAlign: "center" }}>
                      <FontAwesomeIcon icon={faGithub} size="2x" />
                    </Grid>
                    <Grid item>Github</Grid>
                  </Grid>
                </a>
                <a
                  href="https://twitter.com/MetisEarth"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Grid container spacing={2} alignItems="center">
                    <Grid item sm={4} xs={4} style={{ textAlign: "center" }}>
                      <FontAwesomeIcon icon={faTwitter} size="2x" />
                    </Grid>
                    <Grid item>Twitter</Grid>
                  </Grid>
                </a>
                <a
                  href="https://discord.gg/bqy7qjhdBk"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Grid container spacing={2} alignItems="center">
                    <Grid item sm={4} xs={4} style={{ textAlign: "center" }}>
                      <FontAwesomeIcon icon={faDiscord} size="2x" />{" "}
                    </Grid>
                    <Grid item>Discord</Grid>
                  </Grid>
                </a>
                <a
                  href="https://metisearth.gitbook.io/metisearth/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Grid container spacing={2} alignItems="center">
                    <Grid item sm={4} xs={4} style={{ textAlign: "center" }}>
                      <FontAwesomeIcon icon={faBook} size="2x" />{" "}
                    </Grid>
                    <Grid item>Docs</Grid>
                  </Grid>
                </a>
              </div> */}
              {/* <button
                onClick={() => {
                  setMarketplace(true);
                  setOpen(false);
                }}
                className={styles["button"]}
              >
                Marketplace
              </button> */}
            </Drawer>
          }
        </div>
      </>
    );
  }
);
