.container {
  display: flex;
  flex: 1;

  position: relative;

  height: 100%;
}

.map {
  width: 100%;
  height: 100%;

  z-index: 2;
}

.display-grid {
  position: absolute;

  bottom: 2%;
  right: 2%;

  z-index: 3;

  background-color: white;

  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: flex-start;

  border-radius: 8px;
  padding: 12px;
}

@media screen and (max-width: 999px) {
  .display-grid {
    bottom: unset;
    padding: 1px;
    right: 0;
    top: 5vh;
  }
}

@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .display-grid {
    bottom: unset;
    padding: 1px;
    right: 0;
    top: 5vh;
  }
}
.display-grid > p {
  margin: 0 12px 0 0;
}

.list-marketplace {
  position: absolute;

  bottom: 83%;
  right: 2%;

  z-index: 3;

  background-color: #313131;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: flex-start;

  padding: 12px 24px;

  color: white;
}

.list-marketplace-button {
  padding: 8px 48px;
  background-color: #75e78e;
  color: black;
  border: none;

  font-size: 24px;
  font-family: "Space Grotesk";
  font-weight: 500;

  cursor: pointer;
}

.container div:first-child > div:nth-child(2) > div:first-child {
  position: absolute;
  top: unset;
  bottom: 0%;
}

.container div:first-child > div:nth-child(2) > div:last-child {
  display: none;

  top: 10%;
}

@media screen and (max-width: 999px) {
  .container div:first-child > div:nth-child(2) > div:first-child {
    bottom: 0%;
    top: unset;
  }
}

@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .container div:first-child > div:nth-child(2) > div:first-child {
    bottom: 0%;
    top: unset;
  }
}

.button {
  width: 110px;
  height: 34px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  justify-content: space-evenly;
  cursor: pointer;
  z-index: 1;
  border: 3px solid #11c9e3;
  justify-content: space-evenly;
  align-items: center;
}
