.modal-header {
  background-color: #f3f3f3;
  padding: 18px 36px 18px 53px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 25px;
  font-weight: 500;
}

.select-img {
  margin-right: 10px;
}

.arrow-back-icon {
  margin-right: 15px;
  cursor: pointer;
}

.land-card {
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding: 20px 0;
}

.land-img {
  width: 128px;
  height: 128px;
  border-radius: 4px;
}

.country-flag {
  width: 20px;
  height: 20px;
  margin: 0 6px;
}

.land-info-country {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: fit-content;
}

.land-info {
  min-width: 250px;
  margin-left: 24px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 2;
}

.land-container {
  display: flex;
  flex-direction: row;
  width: 630px;
  justify-content: space-between;

  padding: 18px;

  background-color: #f3f3f3;
}

.land-info-name {
  font-size: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  word-break: break-all;
}

.info-text {
  font-size: 22px;
}

.input-land-name {
  width: 90%;
  background: #e5e3e3;
  border: none;
  padding: 14px 30px;
  font-size: 18px;
  margin-top: 8px;
  outline: none;
  font-family: "Space Grotesk";
}

.input-land-name::placeholder {
  color: #c4c4c4;
}

.input-starting-price {
  background: #e5e3e3;
  border: none;
  padding: 20px 30px;
  font-size: 18px;
  outline: none;
  font-family: "Space Grotesk";

  width: 175px;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-starting-price::placeholder {
  color: #c4c4c4;
}

.listing-header {
  padding: 10px 15px;
  border: 9px solid #f3f3f3;
  border-bottom: none;

  font-size: 22px;
  font-weight: bold;
}

.listing-content {
  padding: 10px 15px;
  border: 9px solid #f3f3f3;
  width: 350px;
}

.starting-price-container {
  display: flex;
}

.currency-selector {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #454545;
  width: 200px;
}

.currency-rate {
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #666666;
}

.complete-listing {
  width: 230px;
  padding: 8px 48px;
  background-color: #75e78e;
  color: black;
  border: none;

  margin-top: 40px;
  margin-bottom: 20px;

  font-size: 24px;
  font-family: "Space Grotesk";
  font-weight: 500;

  cursor: pointer;
}

.dialog-content {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 0 40px;
  flex-wrap: wrap;
}

@media screen and (max-width: 770px) {
  .land-container {
    width: 94%;
  }
}

@media screen and (max-width: 550px) {
  .land-container {
    width: 80%;
    flex-direction: column;
    align-items: center;
  }
  .input-land-name {
    width: 80%;
  }
  .listing-content {
    max-width: 300px;
  }
}

@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .land-container {
    width: 80%;
    flex-direction: column;
    align-items: center;
  }
  .input-land-name {
    width: 80%;
  }
  .listing-content {
    max-width: 300px;
  }
}
