import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "leaflet/dist/leaflet.css";

import "./index.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { appStore } from "./store/appStore";
import { SnackbarProvider } from "notistack";
import { metamaskEnabled } from "./const/const";
import { mapStore } from "./store/mapStore";

declare global {
  interface Window {
    ethereum:
      | {
          request: (params: { method: string; params: any[] }) => void;
        }
      | undefined
      | any;

    opera?: any;
  }
}

if (metamaskEnabled) {
  try {
    window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [
        {
          chainId: process.env["CHAIN_ID"],
          chainName: "Base",
          nativeCurrency: {
            name: "Ethereum",
            symbol: "ETH", // 2-6 characters long
            decimals: 18,
          },
          rpcUrls: [process.env["RPC_URL"]],
        },
      ],
    });
  } catch {}
}

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider maxSnack={4}>
        <App appStore={appStore} mapStore={mapStore} />
      </SnackbarProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
