.premium_button {
  font-size: 18px !important;
  display: block !important;
  padding: 11px 6px !important;
  /* background-color: #ed9b3b !important; */
  color: #fff !important;
  border-radius: 16px;
  border: 1px solid #fff !important;
  background: var(
    --1,
    linear-gradient(265deg, #11c9e3 -7.11%, #ba15f4 103.55%)
  );
}

button.premium_button:hover::after {
  background-color: #00000000;
}

.header {
  /* height: calc(81px - 32px); */

  /* background-color: rgba(49, 49, 49, 1); */
  height: 87px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 16px 70px;

  box-shadow: 0px 4px 13px 2px rgba(0, 0, 0, 0.25);
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  /* background: linear-gradient(
    269deg,
    rgba(241, 250, 255, 0.1) -3.09%,
    rgba(241, 250, 255, 0) 52.9%,
    rgba(241, 250, 255, 0.1) 103.34%
  ); */
  backdrop-filter: blur(8.5px);
  /* background: linear-gradient(90deg, #8634ee 0%, #51a2ebfc 100%); */
  position: fixed;
  z-index: 11;
  width: -webkit-fill-available;
}

/* @media screen and (max-width: 999px) {
  .header {
    position: relative;
    background: var(
      --1,
      linear-gradient(265deg, #11c9e3 -7.11%, #ba15f4 103.55%)
    );
  }
} */

.project-info,
.project-info-sec {
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;
}

.project-title {
  background: linear-gradient(88deg, #11c9e3 4.88%, #88d09b 97.49%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.project-info-sec {
  flex-direction: column;
  align-items: baseline;
}

.project-title-sec {
  background: linear-gradient(88deg, #11c9e3 4.88%, #88d09b 97.49%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.info-wrap {
  display: flex;
  align-items: center;
}

.logo {
  width: 70px;
  height: 70px;
}

.project-title,
.project-title-sec {
  color: white;
  margin-left: 14px;
  font-size: 24px;
}

.socials {
  margin-left: auto;
}

.socials > a {
  color: white;
}

.socials > a:hover > svg {
  color: #15a2ab;

  transition: color 0.3s ease;
}

.socials > a > svg {
  margin: 0 8px;

  transition: color 0.3s ease;
}

.footer {
  composes: header;
  position: unset;
  background: #000;
  /* background-color: rgba(28, 28, 28, 1);
   */
  height: auto;
}

/* .copyright {
  color: white;

  margin-left: 24px;
  font-size: 18px;
} */

.controls > .socials {
  margin-right: 12px;
}

.btn-connect {
  border-radius: 16px;
  background-image: linear-gradient(#fff, #fff),
    linear-gradient(to right, #ba15f4, #11c9e3);
  background-origin: border-box;
  background-clip: content-box, border-box;
  border: 1px double #0000;
  padding: 1px;
  color: #ba15f4;
  display: flex;
  order: 2;
  justify-content: center;
  align-items: center;
  width: 122px;
  height: 46px;
  font-size: 20px;
  margin-left: 4px;
  /* padding: 12px 32px; */
  cursor: pointer;

  transition: color 0.3s ease, background-color 0.3s ease;
}

@media screen and (max-width: 419px) {
  button.icn-btn {
    padding: 3px;
  }
}

@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  button.icn-btn {
    padding: 3px;
  }
}

@media screen and (max-width: 1000px) {
  /* .project-title {
        display: none;
    } */

  .footer {
    flex-direction: column-reverse;
    height: auto;
  }

  .footer > .socials {
    margin: 12px 0;
  }
}

@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .footer {
    flex-direction: column-reverse;
    height: auto;
  }

  .footer > .socials {
    margin: 12px 0;
  }
}

/* controls for navbar */
.controls {
  display: flex;
  align-items: center;

  margin-left: 20px;

  margin-left: auto;
}

.controls > a {
  text-decoration: none;
}

.button {
  display: flex;

  justify-content: center;
  align-items: center;

  background-color: #454545;
  color: #000;

  font-size: 20px;
  border: none;

  padding: 12px 32px;

  cursor: pointer;

  transition: color 0.3s ease, background-color 0.3s ease;

  background-color: rgba(0, 0, 0, 0);
}

.button:hover {
  /* background-color: #3f3e3e; */
  color: #c9c9c9;

  transition: color 0.3s ease, background-color 0.3s ease;
  position: relative;
}

.button:hover::after {
  content: "";
  position: absolute;
  bottom: 7%;
  left: 20%;
  background-color: #fff;
  height: 1px;
  width: 62%;
}

.icon {
  margin-right: 8px;
}

.account-block {
  display: flex;
  flex-direction: row;

  color: white;
  height: 47px;

  font-size: 20px;
  font-weight: 500;
}

.balance {
  display: flex;
  flex-direction: row;
  color: #fff;
  align-items: center;
  justify-content: center;

  /* background-color: #454545; */

  padding: 0 14px;
}

.balance > img {
  width: 25px;
  height: 25px;

  margin-right: 7px;
}

.balance > p {
  margin: 0;
}

.address {
  /* background-color: #5c5c5c; */
  margin: 0;
  color: #fff;
  display: flex;

  justify-content: center;
  align-items: center;
  padding: 0 14px;
}

.premium_button_desktop {
  /* margin-right: 10px; */
  font-size: 20px !important;
}

.drawer-menu a {
  color: #15a2ab;
  margin-right: 16px;
  font-weight: 500;
}

@media screen and (max-width: 1150px) {
  .header:first-child {
    padding: 18px;
  }

  .header > .controls > .socials {
    display: none;
  }

  .account-block {
    font-size: 16px;
  }

  .balance {
    padding: 0 5px;
  }

  .button {
    padding: 8px;

    font-size: 18px;
  }

  .controls > a {
    margin-left: 10px !important;
  }

  .currency {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .project-title {
    display: none;
  }

  .project-title-sec {
    font-size: 16px;
  }

  .copyright {
    font-size: 12px;
  }
}

@media screen and (max-width: 500px) {
  .balance {
    width: 100%;
  }
  .button img,
  .button svg {
    display: none;
  }

  .controls > .button {
    display: none;
  }
  .btn-help svg {
    display: block;
  }

  .btn-help {
    padding-right: 25px;
  }
  .button {
    padding: 0;
  }
}

@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .balance {
    width: 100%;
  }
  .button img,
  .button svg {
    display: none;
  }

  .controls > .button {
    display: none;
  }

  .btn-help svg {
    display: block;
  }

  .btn-help {
    padding-right: 25px;
  }

  .button {
    padding: 0;
  }
}

@media screen and (max-width: 375px) {
  .logo {
    width: 30px;
    height: 30px;
  }
}

@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .logo {
    width: 30px;
    height: 30px;
  }
}

@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .project-title {
    display: none;
  }
  .project-title-sec {
    font-size: 16px;
  }

  .copyright {
    font-size: 12px;
  }
}

@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .header:first-child {
    padding: 18px;
  }

  .header > .controls > .socials {
    display: none;
  }

  .account-block {
    font-size: 16px;
  }

  .balance {
    padding: 0 5px;
  }

  .button {
    padding: 8px;

    font-size: 18px;
  }

  .controls > a {
    margin-left: 10px !important;
  }

  .currency {
    display: none;
  }
}

@media screen and (max-width: 1200px) {
  .socials {
    display: none;
  }
  .premium_button_desktop {
    margin-right: 10px;
  }
}

@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .socials {
    display: none;
  }
  .premium_button_desktop {
    margin-right: 10px;
  }
}

.copyright {
  color: gray;
}

.btn-help {
  padding-right: 25px;
}
