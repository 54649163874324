import {tileToCoords} from "../utils/mapUtils";

interface RawPlace {
    boundingbox: [string, string, string, string]
    class: string
    display_name: string
    icon: string,
    importance: number,
    lat: string,
    licence: string,
    lon: string,
    osm_id: string,
    osm_type: string,
    place_id: string,
    type: string
}

interface RawCountryPlace {
    address: {
        country: string
        country_code: string
    }
}

export interface Place extends Omit<RawPlace,
    'display_name' | 'osm_id' | 'osm_type' | 'place_id' | 'boundingbox'
>{
    displayName: string
    osmId: string
    osmType: string
    placeId: string

    boundingBox: [number, number, number, number]
}

function format(rawPlace: RawPlace): Place {
    const { display_name, osm_id, osm_type, place_id, boundingbox, ...otherProps } = rawPlace

    return {
        ...otherProps,

        boundingBox: boundingbox.map(v => parseFloat(v)) as [number, number, number, number],
        displayName: display_name,
        osmId: osm_id,
        osmType: osm_type,
        placeId: place_id
    }
}


export function searchPlaces(query: string) {
    return fetch(`https://nominatim.openstreetmap.org/search?q=${query}&format=json`)
        .then(res => res.json())
        .then(res => (res as RawPlace[]).map(place => format(place)))
}


export function getCountry(coordinates: { x: number, y: number }) {
    const coords = tileToCoords(coordinates)

    return fetch(`https://nominatim.openstreetmap.org/reverse?lat=${coords.lat}&lon=${coords.lng}&zoom=3&format=json`, {
        headers: {
            'accept-language': 'en'
        }
    })
        .then(res => res.json())
        .then(res => res as RawCountryPlace)
        .then(res => ({
            address: {
                country: res.address.country,
                countryCode: res.address.country_code
            }
        }))
}
