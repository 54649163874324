import React, { useEffect, useState, useCallback, FC } from "react";
import { Select, MenuItem, SelectChangeEvent, Grid } from "@mui/material";
import { useQuery } from "react-query";
import { makeStyles } from "@mui/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FormControl from "@mui/material/FormControl";

import { getLands, Country } from "../../../api/parser";
import Flag from "react-world-flags";
import { NOT_SELECTED_COUNTRY_CODE } from "../../../const/const";

const DEFAULT_PAGE_NUMBER = 1;

interface CountriesProps {
  selectCountry?: (countryCode: string) => void;
  isPremiumLands?: boolean;
  isMintedLands?: boolean;
  inPopover?: boolean;
}

const Countries: FC<CountriesProps> = ({
  selectCountry,
  isPremiumLands,
  isMintedLands,
  inPopover,
}) => {
  const styles = inPopover ? useStylesForPopover() : useStyles();
  const [selectedCountry, setSelectedCountry] = useState<string>(
    NOT_SELECTED_COUNTRY_CODE
  );
  const [countries, setCountries] = useState<Country[]>([]);

  const { isLoading, error, data, refetch } = useQuery(
    ["get-countries", DEFAULT_PAGE_NUMBER],
    () =>
      getLands(DEFAULT_PAGE_NUMBER, undefined, isPremiumLands, isMintedLands)
  );

  useEffect(() => {
    if (!data?.countries) {
      return;
    }

    setCountries(data.countries);
  }, [data]);

  useEffect(() => {
    refetch();
  }, [isPremiumLands, isMintedLands]);

  useEffect(() => {
    if (selectCountry) {
      selectCountry(selectedCountry);
    }
  }, [selectedCountry, selectCountry]);

  const handleChange = (event: SelectChangeEvent<string>) => {
    setSelectedCountry(event.target.value);
  };

  const renderValue = useCallback((value: string) => {
    if (value === NOT_SELECTED_COUNTRY_CODE) return <>Countries</>;

    return (
      <div>
        <Flag code={value} className={styles.flag} />
        &nbsp;
        {value}
      </div>
    );
  }, []);

  const handleClick = (itemCountry: string) => {
    if (selectedCountry === itemCountry) {
      setSelectedCountry(NOT_SELECTED_COUNTRY_CODE);
    }
  };

  return (
    <Select
      value={selectedCountry}
      onChange={handleChange}
      className={styles.select}
      IconComponent={ArrowIcon}
      renderValue={renderValue}
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        PaperProps: {
          className: styles.paper,
        },
      }}
    >
      <MenuItem
        value={NOT_SELECTED_COUNTRY_CODE}
        className={styles.menuItem}
        classes={{ selected: styles.selected }}
      >
        All countries
      </MenuItem>
      {countries.map((item) => (
        <MenuItem
          value={item.country}
          className={styles.menuItem}
          key={item.country}
          classes={{ selected: styles.selected }}
          onClick={() => {
            handleClick(item.country);
          }}
        >
          <Grid container alignItems="center" justifyContent={"space-between"}>
            <Grid
              container
              item
              className={styles.flagAndNameContainer}
              alignItems="center"
            >
              <Grid item className={styles.flagContainer}>
                <Flag code={item.country} className={styles.flag} />
              </Grid>
              <Grid item className={styles.textContainer}>
                {item.countryName}
              </Grid>
            </Grid>
            <Grid item className={styles.countContainer}>
              {item.count}
            </Grid>
          </Grid>
        </MenuItem>
      ))}
    </Select>
  );
};

const ArrowIcon = () => {
  const styles = useStyles();

  return <KeyboardArrowDownIcon className={styles.arrowIcon} />;
};

const useStyles = makeStyles(() => ({
  select: {
    background:
      "linear-gradient(#fff, #fff), linear-gradient(to right, #11c9e3, #ba15f4)",
    backgroundOrigin: "border-box",
    backgroundClip: "content-box, border-box",
    padding: "1px",
    border: "1px double #0000",
    borderRadius: "16px",
    color: "#BA15F4",
    height: "45px",
    width: "145px",
    marginRight: "16px",
    fontSize: "19px",
    fontFamily: "Inter",
    "& > .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& > .MuiSelect-select": {
      paddingRight: "5px !important",
    },
  },
  arrowIcon: {
    color: "#ba15f4",
    paddingRight: "5px",
  },
  menuItem: {
    background: "#F1FAFF",
    color: "#000",
    height: "80px",
    width: "300px",
    fontFamily: "Space Grotesk",
    fontWeight: 400,
    fontSize: "20px",
  },
  selected: {
    background:
      "var(--1, linear-gradient(265deg, #11c9e3 -7.11%, #ba15f4 103.55%)) !important",
    color: "#fff",
  },
  paper: {
    borderRadius: "0",
    width: "300px",
    padding: 0,
    background: "#11C9E3",
    maxHeight: "400px",
    marginLeft: "34px",
    marginTop: "-8px",
    "&::-webkit-scrollbar": {
      background: "#E5E3E3",
      width: "17px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#C8C5C5",
    },
  },
  flagAndNameContainer: {
    width: "80%",
  },
  flagContainer: {
    width: "40px",
  },
  textContainer: {
    width: "150px",
    whiteSpace: "normal",
  },
  flag: {
    width: "25px",
    height: "15px",
  },
  countContainer: {
    paddingRight: "10px",
  },
  "@media screen and (max-width: 695px)": {
    select: {
      width: "100%",
      margin: 0,
      marginTop: "8px",
    },
  },
  "@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio:3)":
    {
      select: {
        width: "100%",
        margin: 0,
        marginTop: "8px",
      },
    },
}));

const useStylesForPopover = makeStyles(() => ({
  select: {
    // background: "#3E3E3E",
    // borderRadius: 0,
    // height: "45px",
    // width: "145px",
    // marginRight: "16px",
    // border: "none",
    // color: "#fff",
    // fontSize: "20px",
    background:
      "linear-gradient(#fff, #fff), linear-gradient(to right, #11c9e3, #ba15f4)",
    backgroundOrigin: "border-box",
    backgroundClip: "content-box, border-box",
    padding: "1px",
    border: "1px double #0000",
    borderRadius: "16px",
    color: "#BA15F4",
    height: "45px",
    width: "145px",
    marginRight: "16px",
    fontSize: "19px",
    fontFamily: "Inter",
    "& > .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& > .MuiSelect-select": {
      paddingRight: "5px !important",
    },
  },
  arrowIcon: {
    color: "#FFF",
    paddingRight: "5px",
  },
  menuItem: {
    background: "#454545",
    color: "#FFF",
    height: "80px",
    width: "300px",
    fontFamily: "Space Grotesk",
    fontWeight: 400,
    fontSize: "20px",
  },
  selected: {
    backgroundColor: "#4AA657 !important",
  },
  paper: {
    borderRadius: "0",
    width: "300px",
    padding: 0,
    background: "#454545",
    maxHeight: "400px",
    marginLeft: "34px",
    marginTop: "-8px",
    "&::-webkit-scrollbar": {
      background: "#E5E3E3",
      width: "17px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#C8C5C5",
    },
  },
  flagAndNameContainer: {
    width: "80%",
  },
  flagContainer: {
    width: "40px",
  },
  textContainer: {
    width: "150px",
    whiteSpace: "normal",
  },
  flag: {
    width: "25px",
    height: "15px",
  },
  countContainer: {
    paddingRight: "10px",
  },
}));

export { Countries };
