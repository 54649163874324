import styles from "./Help.module.css";

export default function Help() {
  return (
    <section className={styles.section}>
      <h1 className={styles.title}>
        How-To Guide: Using the NovaEarth Metaverse
      </h1>
      <h3 className={styles.subtitle}>Step 1: Setup Your Wallet</h3>
      <p>
        Before you can dive into the NovaEarth Metaverse, ensure you have either
        MetaMask or Trust Wallet installed and set up. If you haven't already,
        download and create your digital wallet. This will be your gateway to
        the Metaverse.
      </p>
      <h3 className={styles.subtitle}>
        Step 2: Access the NovaEarth Metaverse
      </h3>
      <ul>
        <span>If using MetaMask:</span>
        <li>Open your browser and head to the MetaMask extension.</li>
        <span>If using Trust Wallet:</span>
        <li>Open the Trust Wallet app and select the DApp browser.</li>
      </ul>
      <h3 className={styles.subtitle}>
        Step 3: Connect Your Wallet to the NovaEarth Metaverse
      </h3>
      <ul>
        <span>Once on the NovaEarth Metaverse website:</span>
        <li>Click on the option to "Connect Wallet."</li>
        <li>
          Ensure your digital wallet (MetaMask or Trust Wallet) is selected.
        </li>
        <li>Confirm the connection request on your wallet.</li>
      </ul>
      <h3 className={styles.subtitle}>Step 4: Explore!</h3>
      <ul>
        <li>
          Utilize the interface to explore different lands within the Metaverse.
          You can navigate through the platform just as you would any
          interactive map.
        </li>
        <li>
          If you have a particular location in mind, use the "search" function
          to quickly locate specific areas.
        </li>
      </ul>
      <h3 className={styles.subtitle}>Step 5: Claim Your Virtual Land</h3>
      <ul>
        <span>
          Found a piece of the virtual world you love? It's time to make it
          yours!
        </span>
        <li>Click on the desired location.</li>
        <li>Choose the "Mint" option to claim this piece of virtual land.</li>
        <li>
          This process will generate an NFT (Non-Fungible Token) for you,
          signifying your ownership of this particular virtual property.
        </li>
      </ul>
      <h3 className={styles.subtitle}>Step 6: Confirm and Celebrate</h3>
      <ul>
        <span>
          Your wallet will ask for a confirmation to complete the "minting"
          process.
        </span>
        <li>Confirm the action on your wallet.</li>
        <li>
          Once completed, you're now a proud owner of a piece of the NovaEarth
          Metaverse!
        </li>
      </ul>
      <h3 className={styles.subtitle}>
        Remember to always keep your digital wallet credentials safe and never
        share them with anyone. Enjoy your exploration and ownership in the
        NovaEarth Metaverse!
      </h3>
    </section>
  );
}
