.dialog-title {
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;

  /* background-color: #f3f3f3; */
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  background: linear-gradient(
    269deg,
    rgba(241, 250, 255, 0.1) -3.09%,
    rgba(241, 250, 255, 0) 52.9%,
    rgba(241, 250, 255, 0.1) 103.34%
  );
  backdrop-filter: blur(8.5px);

  padding: 12px 60px;
}

.dialog-title > h2 {
  font-weight: 500;
  margin: 0;
}

.dialog-content {
  padding: 24px;

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  background: #f1faff;
}

.dialog-content > div:first-child {
  display: grid;

  grid-template-columns: 1fr 1fr;
}

@media screen and (max-width: 600px) {
  .dialog-content > div:first-child {
    grid-template-columns: 1fr;
  }
}

@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .dialog-content > div:first-child {
    grid-template-columns: 1fr;
  }
}

.earth-land {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 12px;
  border-radius: 16px;
  border: 1px solid #11c9e3;
  background: #fff;
}

.earth-land > img,
.earth-land > span {
  border-radius: 8px;
}

.land-info {
  min-width: 250px;
  margin-left: 24px;
}

.land-info a {
  color: #11c9e3;
  text-decoration: none;
}

.view-btn {
  margin-top: 1.33em !important;
  margin-bottom: 10px !important;
  min-width: 64px !important;
  height: fit-content;
  border-radius: 16px !important;
  border: 1px solid #11c9e3 !important;
  color: #11c9e3 !important;
  cursor: pointer;
}

.premium-border {
  position: absolute;
  left: 0;
  top: 0;
}

.popover-filter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.popover-filter div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.popover-filter div input {
  width: 60px;
  height: 20px;
  padding: 5px;
}

.filters > div:first-child {
  border-radius: 16px;
  background: var(
    --1,
    linear-gradient(265deg, #11c9e3 -7.11%, #ba15f4 103.55%)
  );
}

.filters > div:nth-child(2) {
  border: double 1px transparent;
  border-radius: 16px;
  background-image: linear-gradient(white, white),
    linear-gradient(to right, #ba15f4, #11c9e3);
  background-origin: border-box;
  background-clip: content-box, border-box;

  padding: 0.5px 0.2px;

  color: #ba15f4;
}

/* .filters > div:last-child {
  border-radius: 16px;
  background: var(
    --1,
    linear-gradient(265deg, #11c9e3 -7.11%, #ba15f4 103.55%)
  );
} */

@media screen and (max-width: 695px) {
  .filters {
    margin-top: 10px;
    flex-direction: column;
  }

  .filters-container {
    width: 100%;
  }

  .title {
    width: 100%;
  }

  .title-block {
    flex-direction: column;
    align-items: flex-start !important;
  }

  .search-block {
    width: 100%;
    margin: 0 !important;
  }

  .dialog-title {
    padding: 12px 24px;
  }

  .title-block-container {
    width: 100%;
  }
}

@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .filters {
    margin-top: 10px;
    flex-direction: column;
  }

  .filters-container {
    width: 100%;
  }

  .title {
    width: 100%;
  }

  .title-block {
    flex-direction: column;
    align-items: flex-start !important;
  }

  .search-block {
    width: 100%;
    margin: 0 !important;
  }

  .dialog-title {
    padding: 12px 24px;
  }

  .title-block-container {
    width: 100%;
  }
}

@media screen and (max-width: 580px) {
  .earth-land {
    flex-direction: column;

    justify-content: center;
    align-items: center;
  }

  .land-info {
    display: flex;
    flex-direction: column;

    text-align: center;
    align-items: center;
    justify-content: center;
  }

  .view-btn {
    margin: 0;
  }
}

@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .earth-land {
    flex-direction: column;

    justify-content: center;
    align-items: center;
  }

  .land-info {
    display: flex;
    flex-direction: column;

    text-align: center;
    align-items: center;
    justify-content: center;
  }

  .view-btn {
    margin: 0;
  }
}
