import React, { useState } from "react";

import { observer } from "mobx-react-lite";
import { ViewLands } from "./ViewLands";
import { useQuery } from "react-query";
import { getLands } from "../../api/parser";
import { appStore, AppStore } from "../../store/appStore";
import { mapStore, MapStore } from "../../store/mapStore";
import { tileToCoords } from "../../utils/mapUtils";
import { LatLngTuple } from "leaflet";
import { useNavigate } from "react-router-dom";

interface ViewMyLands {
  open: boolean;
  handleClose: () => void;

  appStore: AppStore;
  mapStore: MapStore;
}

export const ViewMyLands = observer(
  (props: ViewMyLands): React.ReactElement => {
    const [page, setPage] = useState(1);
    const { isLoading, error, data } = useQuery(
      ["get-my-lands", page, appStore.account, props.open],
      () => getLands(page, appStore.account),
      {
        enabled: props.open,
      },
    );
    const navigate = useNavigate();

    return (
      <ViewLands
        open={props.open}
        handleClose={props.handleClose}
        lands={data?.tiles}
        isLoading={isLoading}
        error={Boolean(error)}
        title="My lands"
        currentPage={page}
        pages={data?.pages ?? 1}
        onPageChanged={(p) => setPage(p)}
        onView={(coords) => {
          props.mapStore.map.closePopup();
          props.mapStore.popupBound = null;
          props.mapStore.popupToOpen = null;
          navigate(`/explore?x=${coords.x}&y=${coords.y}`);
          props.handleClose();
        }}
        isMyLands={true}
      />
    );
  },
);
