import React from "react";
import styles from "./styles.module.css";
import logo from "../../img/nova_logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDiscord,
  faGithub,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faBook } from "@fortawesome/free-solid-svg-icons";

export const Footer = (): React.ReactElement => {
  return (
    <div className={styles["footer"]}>
      <div className={styles["project-info-sec"]}>
        <div className={styles["info-wrap"]}>
          <img className={styles["logo"]} src={logo} alt="logo" />

          <p className={styles["project-title-sec"]}>
            NovaEarth® MetaVerse by NovaCore
          </p>
        </div>

        <h4 className={styles["copyright"]}>
          © NovaEarth is a registered trademark. All rights reserved 2023 by the
          developers of NovaCore
        </h4>

        {/* <p className={styles["copyright"]}>© 2022 All rights reserved</p> */}
      </div>

      {/* <div className={styles["socials"]}>
        <a
          href="https://github.com/metisEarth-com/"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faGithub} size="2x" />
        </a>
        <a
          href="https://twitter.com/MetisEarth"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faTwitter} size="2x" />
        </a>
        <a
          href="https://discord.gg/bqy7qjhdBk"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faDiscord} size="2x" />
        </a>
        <a
          href="https://metisearth.gitbook.io/metisearth/"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faBook} size="2x" />
        </a>
      </div> */}
    </div>
  );
};
