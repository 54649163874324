import React, { FC } from "react";
import { Grid, Switch, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Crown from "../../../img/crown.svg";

interface PremiumProps {
  isPremiumLands?: boolean;
  togglePremiumLands?: () => void;
  withCrown?: boolean;
  inPopover?: boolean;
}

const Premium: FC<PremiumProps> = ({
  isPremiumLands,
  togglePremiumLands,
  withCrown,
  inPopover,
}) => {
  const styles = inPopover ? useStylesInPopover() : useStyles();

  return (
    <Grid
      className={styles.premium}
      alignItems="center"
      justifyContent="space-between"
      container
      style={{ width: withCrown ? 180 : 150 }}
    >
      {withCrown && (
        <img src={Crown} style={{ paddingRight: 5 }} alt="Premium" />
      )}
      <Grid item>
        <Typography className={styles.premiumText}>Premium</Typography>
      </Grid>
      <Grid item>
        <Switch
          checked={isPremiumLands}
          onClick={togglePremiumLands}
          classes={{
            thumb: styles.thumb,
            track: styles.track,
            checked: styles.checked,
          }}
        />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  premium: {
    background:
      "var(--1, linear-gradient(265deg, #11C9E3 -7.11%, #BA15F4 103.55%))",
    borderRadius: "16px",
    height: "45px",
    color: "#fff",
    paddingLeft: "8px",
    marginRight: "16px",
  },
  premiumText: {
    fontSize: "20px",
    fontFamily: "Space Grotesk",
  },
  thumb: {
    background: "#F37070",
    width: "19px",
    height: "19px",
    borderRadius: "2px",
    marginTop: "3px",
  },
  track: {
    background: "#CA7474",
    width: "40px",
    height: "20px",
    borderRadius: "2px",
    opacity: "1 !important",
  },
  checked: {
    "& > .MuiSwitch-thumb": {
      background: "#75E78E",
    },
    "& + .MuiSwitch-track": {
      backgroundColor: "#65C57A !important",
    },
  },
  "@media screen and (max-width: 695px)": {
    premium: {
      width: "100%",
      margin: 0,
    },
  },
  "@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio:3)":
    {
      premium: {
        width: "100%",
        margin: 0,
      },
    },
}));

const useStylesInPopover = makeStyles(() => ({
  premium: {
    background:
      "var(--1, linear-gradient(265deg, #11C9E3 -7.11%, #BA15F4 103.55%))",
    height: "45px",
    color: "#fff",
    borderRadius: "16px",
    paddingLeft: "8px",
    marginRight: "16px",
  },
  premiumText: {
    fontSize: "20px",
    fontFamily: "Space Grotesk",
  },
  thumb: {
    background: "#F37070",
    width: "19px",
    height: "19px",
    borderRadius: "2px",
    marginTop: "3px",
  },
  track: {
    background: "#CA7474",
    width: "40px",
    height: "20px",
    borderRadius: "2px",
    opacity: "1 !important",
  },
  checked: {
    "& > .MuiSwitch-thumb": {
      background: "#75E78E",
    },
    "& + .MuiSwitch-track": {
      backgroundColor: "#65C57A !important",
    },
  },
}));

export { Premium };
