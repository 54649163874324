export function setValue(key: StorageKey, value: string) {
    localStorage.setItem(key, value)
}

export function getValue(key: StorageKey) {
    return localStorage.getItem(key)
}

export enum StorageKey {
    ZOOM = 'zoom',
    CENTER_POSITION = 'center_position',
    DISPLAY_GRID = 'display_grid',
    LANDS_BLOCK_WIDTH = 'lands_block_width',
    LANDS_BLOCK_HEIGHT = 'lands_block_height',

    METIS_COST = 'metis_cost',
    LAST_REFRESH = 'last_refresh',

    WL_PROOFS = 'wl_proofs'
}
