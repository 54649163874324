import React from "react";
import styles from "./styles.module.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import heroBackground from "../../img/landing/hero-background.png";
import bgImg from "../../img/landing/Rectangle.png";

import heroPathIcon from "../../img/landing/hero-path-icon.svg";
import projectInfoPathIcon from "../../img/landing/project-info-path-icon.svg";

import roadmapFirstPathIcon from "../../img/landing/roadmap-path-icon-1.svg";
import roadmapSecondPathIcon from "../../img/landing/roadmap-path-icon-2.svg";

import {
  faCommentDots,
  faCompass,
  faCrown,
  faEye,
  faList,
} from "@fortawesome/free-solid-svg-icons";
import Countdown from "react-countdown";
import { format } from "../../utils/countdown";
import { Footer } from "../../components/Navbar/Footer";
import { KingOfProject } from "./components/KingOfProject/KingOfProject";
import { Leaderboards } from "./components/Leaderboards/Leaderboards";
import { useQueries, useQuery } from "react-query";
import { getOwnedLandsCount, getStats } from "../../api/parser";
import { getMetisCostUSD } from "../../api/coinGecko";
import { CircularProgress } from "@mui/material";
import BigNumber from "bignumber.js";
import { premiumTileCost, tileCost } from "../../const/const";
import { getValue, StorageKey } from "../../utils/storageUtils";

export const Landing = (): React.ReactElement => {
  const [ownedLandsCountQuery, metisCostQuery] = useQueries([
    { queryFn: getOwnedLandsCount, queryKey: "get-owned-lands" },
    { queryFn: getMetisCostUSD, queryKey: "get-metis-cost" },
  ]);

  const { isLoading, error, data, refetch } = useQuery(["get-stats"], () =>
    getStats()
  );

  return (
    <div className={styles["landing"]}>
      <div
        className={styles["hero"]}
        // style={{ backgroundImage: `url('${bgImg}')` }}
      >
        <h1>NovaEarth by NovaCore</h1>

        <div className={styles["blocks"]}>
          <div className={styles["description-block"]}>
            <h2>Welcome to NovaEarth, a metaverse by NovaCore</h2>
            <h3>Real-world geolocations represented virtually as NFTs</h3>
          </div>

          {/*<div className={styles["timer-block"]}>*/}
          {/*  <h3>*/}
          {/*      <Countdown*/}
          {/*          date={new Date(1646492400 * 1000)}*/}
          {/*          renderer={(params) => format(params)}*/}
          {/*      />*/}
          {/*  </h3>*/}
          {/*  /!*<h1>Launching February - March 2022</h1>*!/*/}
          {/*</div>*/}
        </div>

        {/* <img className={styles["hero-icon"]} src={heroPathIcon} alt="" /> */}
      </div>

      <div className={styles["description-section"]}>
        <h2>
          Have you ever wanted to <span> own the world?</span>
          <br />
          <Link to="/map3d">
            <button className={styles["btn-nav"]}>Now you can!</button>
          </Link>
        </h2>
      </div>

      <div className={styles["project-info-section"]}>
        <img
          src={projectInfoPathIcon}
          className={styles["project-info-section-icon"]}
          alt=""
        />

        <div className={styles["project-info-block"]}>
          <div>
            {/* <FontAwesomeIcon icon={faEye} size="2x" /> */}
            <h2>What is NovaEarth</h2>
          </div>

          <p>
            <b>NovaEarth</b> is a second earth; a <b>metaverse</b>, existing on
            Base, in which real-world geolocations on a sectioned map correspond
            to user generated virtual environments. These environments can be
            owned, bought, sold, and in the future, customized.
          </p>
        </div>

        <div className={styles["project-info-block"]}>
          <div>
            {/* <FontAwesomeIcon icon={faCompass} size="2x" /> */}
            <h2>How big is NovaEarth</h2>
          </div>

          <p>
            A virtual 1:1 scale version of Earth is inevitable and NovaEarth is
            the beginning of this exciting future. Phase 1 is live and aims to
            determine ownership of digital assets and property inside this
            futuristic virtual metaverse of Earth.
          </p>
        </div>

        <div className={styles["project-info-block"]}>
          <div>
            {/* <FontAwesomeIcon icon={faCommentDots} size="2x" /> */}
            <h2>How is NovaEarth built</h2>
          </div>

          <p>
            NovaEarth is built on top of OSM (OpenStreetMap) & Mapbox
            technologies. We have created a geographically linked digital grid
            layer that spans across the entire planet, allowing{" "}
            <b>Base users</b> to claim ownership of <b>Virtual Land</b> in the
            form of tiles. We believe that demand for Virtual Land will continue
            increasing rapidly, so be sure to claim your own land in key
            locations around the world early on!
          </p>
        </div>

        <div className={styles["project-info-block"]}>
          <div>
            {/* <FontAwesomeIcon icon={faList} size="2x" /> */}
            <h2>NovaEarth contributions</h2>
          </div>

          <p>
            A portion of all revenue will be donated to NovaCore to support
            further development.
          </p>
        </div>
      </div>

      {/* <div className={styles["statistics-section"]}>
        <h2 className={styles["statistics-section-title"]}>Statistics</h2>

        <div>
          <div className={styles["stats-block"]}>
            <h4>Purchased lands</h4>
            {(() => {
              if (isLoading) {
                return (
                  <CircularProgress
                    style={{
                      width: 50,
                      height: 50,
                      strokeWidth: 4,
                      color: "#00a8ff",
                      marginRight: "auto",
                      marginLeft: "auto",
                    }}
                  />
                );
              }

              if (error || !data) {
                return (
                  <p style={{ textAlign: "center" }}>Something went wrong</p>
                );
              }

              return <p>{data.landsMinted + data.premiumLandsMinted}</p>;
            })()}
          </div>

          <div className={styles["stats-block"]}>
            <h4>NovaEarth valuation</h4>
            {(() => {
              if (isLoading) {
                return (
                  <CircularProgress
                    style={{
                      width: 50,
                      height: 50,
                      strokeWidth: 4,
                      color: "#00a8ff",
                      marginRight: "auto",
                      marginLeft: "auto",
                    }}
                  />
                );
              }

              if (error || !data) {
                return (
                  <p style={{ textAlign: "center" }}>Something went wrong</p>
                );
              }

              return (
                <p>
                  ${" "}
                  {new BigNumber(data.landsMinted)
                    .times(tileCost)
                    .plus(
                      new BigNumber(data.premiumLandsMinted).times(
                        premiumTileCost,
                      ),
                    ).times(Number(getValue(StorageKey.METIS_COST)))
                    .toFixed(2)}
                </p>
              );
            })()}
          </div>

          <div className={styles["stats-block"]}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FontAwesomeIcon icon={faCrown} size="2x" />
              <h4>King of NovaEarth</h4>
            </div>

            <KingOfProject />
          </div>
        </div>
      </div> */}

      {/* <Leaderboards metisCostQuery={metisCostQuery} /> */}

      {/* <div className={styles["roadmap-section"]}>
        <div className={styles["roadmap-icons"]}>
          <img src={roadmapFirstPathIcon} alt="" style={{ width: "100%" }} />
          <img src={roadmapSecondPathIcon} alt="" style={{ width: "100%" }} />
        </div>

        <h2>Roadmap</h2>

        <div className={styles["roadmap-step"]}>
          <div className={styles["roadmap-step-title"]}>
            <h3>
              Q1 2022
              <br />
              <span>Mainnet launch</span>
            </h3>
          </div>

          <div className={styles["roadmap-step-description"]}>
            <p>
              Launch of NovaEarth first stage with automated Yield for PLs, lands represented as NFTs and navigation through the world
            </p>
          </div>
        </div>
        <div className={styles["roadmap-step"]}>
          <div className={styles["roadmap-step-description"]}>
            <p>
              Launch of NovaEarth marketplace. Land owners will be able to trade their lands and open auctions
            </p>
          </div>

          <div className={styles["roadmap-step-title"]}>
            <h3>
              Q2 2022
              <br />
              <span>Marketplace</span>
            </h3>
          </div>
        </div>

        <div className={styles["roadmap-step"]}>
          <div className={styles["roadmap-step-title"]}>
            <h3>
              Q3 2022
              <br />
              <span>NovaEarth 3D</span>
            </h3>
          </div>

          <div className={styles["roadmap-step-description"]}>
            <p>
              Users are able to move around and view the breathtaking world virtually in 3D
            </p>
          </div>
        </div>

        <div className={styles["roadmap-step"]}>
          <div className={styles["roadmap-step-description"]}>
            <p>
              Customization is coming to NovaEarth and that means that you can keep building on your land and inviting your friends over in the metaverse
            </p>
          </div>

          <div className={styles["roadmap-step-title"]}>
            <h3>
              Q4 2022
              <br />
              <span>Customization</span>
            </h3>
          </div>
        </div>

        <p
          style={{
            color: "white",
            fontSize: "20px",
            textAlign: "center",
            width: "90%",
            marginBottom: "58px",
          }}
        >
          As a floating Roadmap, timelines are subject to change as we need to
          be flexible and sensitive to the needs of industry, technology, and
          the community.
        </p>
      </div> */}

      <Footer />
    </div>
  );
};
