.search-block {
  position: relative;
  flex: 1;

  display: flex;
  align-items: stretch;
}

.search-input {
  border-radius: 16px;
  border: 1px solid #11c9e3;
  background: #fff;

  padding: 21px 24px;

  height: 18px;
  font-size: 18px;

  width: 100%;
}

@keyframes Search-Block-Hide-Animation {
  0% {
    visibility: visible;
    opacity: 1;
  }

  99% {
    opacity: 0;
  }

  100% {
    visibility: hidden;
  }
}

@keyframes Search-Block-Show-Animation {
  0% {
    visibility: visible;
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.search-input:focus ~ .search-results {
  visibility: visible;
  opacity: 1;

  animation: 0.2s ease Search-Block-Show-Animation;
}

.search-results {
  position: absolute;
  z-index: 9;

  visibility: hidden;
  opacity: 0;

  text-align: center;

  transform: translateY(100%);
  bottom: 0;

  background-color: white;

  width: 100%;

  padding: 12px;
  box-sizing: border-box;

  border-radius: 0 0 3px 3px;

  max-height: 300px;

  overflow: auto;

  box-shadow: 0px 21px 25px 0px rgba(124, 124, 124, 0.2);
  animation: 0.2s ease Search-Block-Hide-Animation;
}
