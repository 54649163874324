import { pageSize } from "../const/const";
import { index2coords } from "../utils/mapUtils";

type TileCoordinates = { x: number; y: number };

interface Leader {
  owner: string;
  lands: number;
}

export interface RawOwnedTile {
  tokenId: string;
  owner: string;

  country: string;
  countryName: string;

  coordinates: {
    x: string;
    y: string;
  };

  isPremium: boolean;
  isMinted: boolean;
  premiumName?: string;
}

export interface MarketplaceListingLand {
  id: string;
  country: string;
  img: string;
  isPremium: boolean;
  name: string;
  owner: string;
  price: number;
  tokensInside: number[];
  history?: any;
  view?: number;
}

interface MarletplaceLandGroup {
  id: string;
  country: string;
  img: string;
  isPremium: boolean;
  name: string;
  owner: string;
  price: number;
  tokensInside: number[];
  history: any[];
}

export interface Country {
  count: number;
  country: string;
  countryName: string;
}

interface ViewAnswer {
  total: number;
  tiles: RawOwnedTile[];
  countries: Country[];
}

interface marketplaceListings {
  total: number;
  listings: MarketplaceListingLand[];
}

export interface OwnedTile
  extends Omit<RawOwnedTile, "coordinates" | "tokenId"> {
  tokenId: number;

  coordinates: {
    x: number;
    y: number;
  };
}

const baseAddress = process.env["PARSER_API_ADDRESS"];

export function getWLProofs(address: string) {
  return fetch(`${baseAddress}/wl?address=${address}`)
    .then((res) => res.json())
    .then((res) => res as string[]);
}

export function getLeaderboards() {
  return fetch(`${baseAddress}/leaderboard`)
    .then((res) => res.json())
    .then((res) => res as Leader[]);
}

export function getKingOfProject() {
  return getLeaderboards().then((res) => res[0]);
}

export function getTiles(
  firstTile: TileCoordinates,
  secondTile: TileCoordinates
): Promise<OwnedTile[]> {
  return fetch(
    `${baseAddress}/tiles?ax=${firstTile.x}&ay=${secondTile.y}&bx=${secondTile.x}&by=${firstTile.y}`
  )
    .then((res) => res.json())
    .then((res) => res as RawOwnedTile[])
    .then((res) =>
      res.map((ot) => {
        const {
          tokenId,
          coordinates,
          owner,
          country,
          countryName,
          isPremium,
          isMinted,
        } = ot;

        return {
          tokenId: parseInt(tokenId),
          owner,
          coordinates: {
            x: parseInt(coordinates.x),
            y: parseInt(coordinates.y),
          },
          country,
          countryName,
          isPremium,
          isMinted,
        };
      })
    );
}

export function getOwnedLandsCount() {
  return fetch(`${baseAddress}/total`)
    .then((res) => res.json())
    .then((res) => res as { count: string })
    .then((res) => parseInt(res.count));
}

export function getStats() {
  return fetch(`${baseAddress}/stats`)
    .then((res) => res.json())
    .then((res) => res as { landsMinted: number; premiumLandsMinted: number });
}

export function getLands(
  page: number,
  owner?: string,
  premium = false,
  minted = false,
  country?: string
) {
  let offset = (page - 1) * pageSize;
  const ownerQ = owner ? `owner=${owner}&` : "";

  return fetch(
    `${baseAddress}/view?${ownerQ}offset=${offset}&limit=${pageSize}&premium=${premium.toString()}&minted=${minted.toString()}${
      country ? `&country=${country}` : ""
    }`
  )
    .then((res) => res.json())
    .then((res) => res as ViewAnswer)
    .then((res) => ({
      pages: Math.ceil(res.total / pageSize),
      tiles: res.tiles.map((land) => {
        const {
          tokenId,
          coordinates,
          owner,
          country,
          countryName,
          isPremium,
          isMinted,
          premiumName,
        } = land;

        return {
          owner,
          tokenId: parseInt(tokenId),
          coordinates: {
            x: parseInt(coordinates.x),
            y: parseInt(coordinates.y),
          },
          country,
          countryName,
          isPremium,
          isMinted,
          premiumName,
        };
      }),
      countries: res.countries,
    }));
}

export function getMarketplaceListing(
  page: number,
  premium = false,
  minted = false,
  price: [number, number],
  query: string,
  lands: [number, number],
  country?: string
) {
  let offset = (page - 1) * pageSize;
  return fetch(
    `${baseAddress}/marketplace/listings?offset=${offset}&limit=${pageSize}&premium=${premium.toString()}&onlyMine=${minted.toString()}${
      country ? `&country=${country}` : ""
    }&search=${query}&priceMin=${price[0]}&priceMax=${price[1]}&landsMin=${
      lands[0]
    }&landsMax=${lands[1]}`
  ).then((res) =>
    res
      .json()
      .then((res) => {
        return res as marketplaceListings;
      })
      .then((res) => ({
        total: Math.ceil(res.total / pageSize),
        lands: res.listings.map((land) => {
          const {
            id,
            owner,
            country,
            name,
            isPremium,
            price,
            tokensInside,
            img,
          } = land;

          return {
            id: id,
            owner,
            country,
            coordinates: index2coords(tokensInside[0]),
            isPremium,
            name,
            price,
            tokensInside,
            img,
          };
        }),
      }))
  );
}

export function getMarketplaceLands(id: string) {
  return fetch(`${baseAddress}/marketplace/get?id=${id}`).then((res) =>
    res
      .json()
      .then((res) => {
        return res as MarletplaceLandGroup;
      })
      .then((res) => ({
        ...res,
      }))
  );
}

export function getProofs(indexes: number[]) {
  return fetch(`${baseAddress}/proofs?indices=${indexes.join(",")}`).then(
    (res) => res.json()
  );
}

export function getGroups() {
  return fetch(`${baseAddress}/groups`).then((res) => res.json());
}
