.section {
  padding-top: 5rem;
  display: flex;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
  flex-direction: column;
}

.title,
.subtitle {
  background: var(
    --1,
    linear-gradient(265deg, #11c9e3 -7.11%, #ba15f4 113.55%)
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}
