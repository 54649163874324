import { useWeb3Modal } from "@web3modal/ethers5/react";
import styles from "./ButtonWallet.module.css";
import { useEffect } from "react";

export default function ButtonWallet({ onAdd, cl = "" }) {
  const { open } = useWeb3Modal();

  const handleOpen = () => {
    open();
    onAdd();
  };

  // const cls = cl ? cl : "btn-wallet";

  return (
    <>
      <button
        className={`${styles["btn-wallet"]} ${styles[`${cl}`]}`}
        onClick={handleOpen}
      >
        Connect
      </button>
    </>
  );
}
