import { createPathComponent } from '@react-leaflet/core';
import { Rectangle as LeafletRectangle } from 'leaflet';
export const Rectangle = createPathComponent(function createRectangle(_ref, ctx) {
  let {
    bounds,
    ...options
  } = _ref;
  const instance = new LeafletRectangle(bounds, options);
  return {
    instance,
    context: { ...ctx,
      overlayContainer: instance
    }
  };
}, function updateRectangle(layer, props, prevProps) {
  if (props.bounds !== prevProps.bounds) {
    layer.setBounds(props.bounds);
  }

  if (props.color !== prevProps.color) {
    layer.setStyle({
      color: props.color
    });
  }

  if (props.opacity !== prevProps.opacity) {
    layer.setStyle({
      opacity: props.opacity
    });
  }

  if (props.fillOpacity !== prevProps.fillOpacity) {
    layer.setStyle({
      fillOpacity: props.fillOpacity
    });
  }

  if (props.weight !== prevProps.weight) {
    layer.setStyle({
      weight: props.weight
    });
  }
});