.btn-wallet {
    color: #ba15f4;
    cursor: pointer;
    background-image: linear-gradient(#fff,#fff),linear-gradient(90deg,#ba15f4,#11c9e3);
    background-origin: border-box;
    background-clip: content-box,border-box;
    border: 1px double #0000;
    border-radius: 16px;
    order: 2;
    justify-content: center;
    align-items: center;
    width: 122px;
    height: 46px;
    margin-left: 4px;
    padding: 1px;
    font-size: 20px;
    transition: color .3s,background-color .3s;
    display: flex;
}