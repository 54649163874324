import { makeObservable, observable } from "mobx";
import Web3 from "web3";
import Web3Modal from "web3modal";
import { Contract } from "web3-eth-contract";
import abi from "../const/abi.json";
import marketplaceAbi from "../const/marketplace.abi.json";
import landsAbi from "../const/landsAbi.abi.json";
import rewardAbi from "../const/reward.abi.json";
import { AbiItem } from "web3-utils";
import EventEmitter from "events";
import { provider as IProvider } from "web3-core";
import {
  errorMessageEnv,
  metamaskEnabled,
  premiumTileCost,
  tileCost,
} from "../const/const";
import BigNumber from "bignumber.js";
import { getWLProofs } from "../api/parser";
import { getValue, setValue, StorageKey } from "../utils/storageUtils";

export class AppStore {
  private client = new Web3(Web3.givenProvider || "");
  private modal = metamaskEnabled
    ? new Web3Modal({
        network: process.env["NETWORK"],
        cacheProvider: true,
      })
    : undefined;
  private contract: Contract;
  private rewardContract: Contract;
  private marketplaceContract: Contract;
  private landsContract: Contract;
  private provider: any;

  public chainId = 0;

  public account = "";
  public balance = 0;
  public approve = false;

  constructor() {
    this.contract = new this.client.eth.Contract(
      abi as AbiItem[],
      process.env["CONTRACT_ADDRESS"]
    );

    this.rewardContract = new this.client.eth.Contract(
      rewardAbi as AbiItem[],
      process.env["REWARD_CONTRACT_ADDRESS"]
    );

    this.landsContract = new this.client.eth.Contract(
      landsAbi as AbiItem[],
      process.env["LANDS_CONTRACT"]
    );

    this.marketplaceContract = new this.client.eth.Contract(
      marketplaceAbi as AbiItem[],
      process.env["MARKETPlACE_CONTRACT_ADDRESS"]
    );

    makeObservable(this, {
      account: observable,
      balance: observable,
    });
  }

  public async disconnect() {
    // @ts-ignore
    await this.client.currentProvider?.close?.();

    this.modal?.clearCachedProvider();
    this.client.setProvider(this.client.givenProvider);

    this.account = "";
  }

  private switchChainIfRequired() {
    if (
      this.chainId !==
        parseInt(process.env["CHAIN_ID"] ?? errorMessageEnv, 8453) &&
      window.ethereum
    ) {
      window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: process.env["CHAIN_ID"] }],
      });
    }
  }

  private subscribeProvider(provider: EventEmitter) {
    provider.on("disconnect", () => {
      void this.disconnect();
    });

    provider.on("accountsChanged", async (accounts: string[]) => {
      this.account = accounts[0] ?? "";
      await appStore.updateBalance();

      const proofs = await getWLProofs(this.account);
      setValue(StorageKey.WL_PROOFS, proofs.join(","));

      window.location.reload();
    });

    provider.on("chainChanged", async (chainId: number) => {
      this.chainId = chainId;

      this.switchChainIfRequired();

      if (this.chainId == parseInt(process.env["CHAIN_ID"])) {
        window.location.reload();
      }
    });

    // provider.on("networkChanged", async () => {
    //   this.chainId = await this.client.eth.getChainId();

    //   this.switchChainIfRequired();
    // });
  }

  public async updateBalance(accounts?: string[]) {
    if (accounts) {
      const balance = await this.client.eth.getBalance(accounts[0] ?? "");
      this.balance = parseInt(balance);
    } else {
      const balance = await this.client.eth.getBalance(this.account);
      this.balance = parseInt(balance);
    }
  }

  public async setProvider(provider: any) {
    this.provider = provider;
  }

  public async connect() {
    try {
      // const provider = (await this.modal?.connect()) as IProvider;

      this.client = new Web3(this.provider);
      this.subscribeProvider(this.provider as unknown as EventEmitter);
      // const accounts = await this.client.eth.getAccounts();
      // const chainId = await this.client.eth.getChainId();

      const accounts = await this.provider?.accounts;
      const chainId = await this.provider?.chainId;

      this.account = accounts[0] ?? "";
      this.chainId = chainId;

      this.switchChainIfRequired();

      await this.updateBalance(accounts);

      const proofs = await getWLProofs(this.account);
      const prev = getValue(StorageKey.WL_PROOFS) === null;
      setValue(StorageKey.WL_PROOFS, proofs.join(","));

      if (prev) {
        window.location.reload();
      }
    } catch (error: any) {
      console.error(error);
    }
  }

  public buyPremiumTiles(
    tiles: number[],
    proofs: Array<string[]>,
    delegate: string
  ) {
    const wlProofs = getValue(StorageKey.WL_PROOFS)?.split(",") ?? [];

    return new Promise((resolve, reject) => {
      const method =
        wlProofs[0] !== ""
          ? this.contract.methods.mintPremiumEarly
          : this.contract.methods.mintPremium;
      const call =
        wlProofs[0] !== ""
          ? method(tiles, proofs, wlProofs)
          : method(tiles, proofs, delegate);

      this.contract.setProvider(this.provider);

      if (
        this.contract?.currentProvider?.selectedAddress ===
        "0x91c63b60193e432bef8f97efbb24150ec66ed85b"
      ) {
        call
          .send({
            from: this.account,
            gas: 7_000_000,
            gasPrice: "150000000",
            value: 0,
          })
          .on("error", (error: any) => {
            reject(error);
          })
          .on("receipt", (receipt: any) => {
            resolve(receipt);
          });
      } else {
        call
          .send({
            from: this.account,
            gas: 7_000_000,
            gasPrice: "150000000",
            value: new BigNumber(
              new BigNumber(tiles.length).times(premiumTileCost)
            )
              .times(1e18)
              .toFixed(0),
          })
          .on("error", (error: any) => {
            reject(error);
          })
          .on("receipt", (receipt: any) => {
            resolve(receipt);
          });
      }
    });
  }

  public isApprovalForAll() {
    return new Promise(() => {
      const isApproveMethod = this.landsContract.methods.isApprovedForAll;
      const isApproveCall = isApproveMethod(
        this.account,
        process.env["MARKETPlACE_CONTRACT_ADDRESS"]
      );
      isApproveCall
        .call({
          from: this.account,
        })
        .then((res: boolean) => {
          this.approve = res;
        });
    });
  }

  public setApprovalForAll() {
    return new Promise((resolve, reject) => {
      const setApprovalForAll = this.landsContract.methods.setApprovalForAll;
      const isApproveCall = setApprovalForAll(
        process.env["MARKETPlACE_CONTRACT_ADDRESS"],
        true
      );
      isApproveCall
        .send({
          from: this.account,
        })
        .on("error", (error: any) => {
          this.approve = false;
          reject(error);
        })
        .on("receipt", (receipt: any) => {
          this.approve = true;
          resolve(receipt);
        });
    });
  }

  public createList(tiles: number[], cost: number, landsName: string) {
    return new Promise((resolve, reject) => {
      const method = this.marketplaceContract.methods.createListing;
      const call = method([
        [...tiles],
        landsName,
        (cost * Math.pow(10, 18)).toString(),
      ]);
      call
        .send({
          from: this.account,
        })
        .on("error", (error: any) => {
          reject(error);
        })
        .on("receipt", (receipt: any) => {
          resolve(receipt);
        });
    });
  }

  public buyTilesFromMarketplace(listingId: string, price: number) {
    return new Promise((resolve, reject) => {
      const method = this.marketplaceContract.methods.purchase;
      const call = method(listingId);
      call
        .send({
          from: this.account,
          gas: 2_000_000,
          gasPrice: "150000000",
          value: new BigNumber(price * Math.pow(10, 18)),
        })
        .on("error", (error: any) => {
          reject(error);
        })
        .on("receipt", (receipt: any) => {
          resolve(receipt);
        });
    });
  }

  public buyTiles(tiles: number[], delegate: string) {
    const wlProofs = getValue(StorageKey.WL_PROOFS)?.split(",") ?? [];

    return new Promise((resolve, reject) => {
      const method =
        wlProofs[0] !== ""
          ? this.contract.methods.mintEarly
          : this.contract.methods.mint;
      const call =
        wlProofs[0] !== "" ? method(tiles, wlProofs) : method(tiles, delegate);
      this.contract.setProvider(this.provider);
      if (
        this.contract?.currentProvider?.selectedAddress ===
        "0x91c63b60193e432bef8f97efbb24150ec66ed85b"
      ) {
        call
          .send({
            from: this.account,
            gas: 2_000_000,
            gasPrice: "150000000",
            value: 0,
          })
          .on("error", (error: any) => {
            reject(error);
          })
          .on("receipt", (receipt: any) => {
            resolve(receipt);
          });
      } else {
        call
          .send({
            from: this.account,
            gas: 2_000_000,
            gasPrice: "150000000",
            value: new BigNumber(new BigNumber(tiles.length).times(tileCost))
              .times(1e18)
              .toFixed(0),
          })
          .on("error", (error: any) => {
            reject(error);
          })
          .on("receipt", (receipt: any) => {
            resolve(receipt);
          });
      }
    });
  }

  public async getClaimedRewards() {
    const response = await this.rewardContract.methods
      .users(this.account)
      .call();

    return response.claimed
      ? new BigNumber(response.claimed).times(1e-9).toFixed(5)
      : "0";
  }

  public async getAvalableRewards() {
    const pending = await this.rewardContract.methods
      .pending(this.account)
      .call();

    return new BigNumber(pending).times(1e-9).toFixed(5);
  }

  public async getLastClaimingTime() {
    const response = await this.rewardContract.methods
      .users(this.account)
      .call();
    const claimedTotal = response.lastClaimTime;

    return claimedTotal;
  }

  public claimReward() {
    return new Promise((resolve, reject) => {
      this.rewardContract.methods
        .claim()
        .send({
          from: this.account,
          value: 0,
        })
        .on("error", (error: any) => {
          console.log("error", error);
          reject(error);
        })
        .on("receipt", (receipt: any) => {
          console.log("receipt", receipt);
          resolve(receipt);
        });
    });
  }
}

export const appStore = new AppStore();
