.result-button {
    background-color: transparent;
    border: none;

    transition: .2s ease;
    cursor: pointer;

    padding: 8px;
    text-align: left;

    width: 100%;
    min-height: 50px;
}

.result-button:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.result-button:hover {
    background-color: rgba(0, 0, 0, .05);
    transition: .2s ease;
}
