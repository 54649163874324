import React, {useCallback, useEffect, useState} from "react";
import { useQuery } from "react-query";
import { MapStore } from "../../../store/mapStore";
import { getMetisCostUSD } from "../../../api/coinGecko";
import Flag from "react-world-flags";
import styles from "./style.module.css";
import {Box, Dialog, Input} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import CloseIcon from "@mui/icons-material/Close";
import {formatMapboxUrl, ZOOM} from "../../../utils/mapUtils";
import { cutAddress } from "../../../utils/format";
import metis from "../../../img/metis-logo.png";
import {AppStore} from "../../../store/appStore";

const ListingLandModal: React.FC<{
  open: boolean;
  handleClose: () => void;
  mapStore: MapStore;
  appStore: AppStore;
  selectedTiles: any[];
}> = ({
  open,
  handleClose,
  mapStore,
  appStore,
  selectedTiles
}) => {
  const matches = useMediaQuery("(max-width:690px)");
  const matchesNumTwo = useMediaQuery("(max-width:550px)");
  const [landName, setLandName] = useState<string>("");
  const [count, setCount] = useState<number>(0);
  const [countInputError, setCountInputError] = useState(false);
  const [landInputError, setLandCountIError] = useState(false);

  const validation = () => {
    setLandCountIError(!(landName.length > 3 && (/^[a-zA-Z0-9\ ]+$/.test(landName))));
    setCountInputError(!(count > 0));
    return (landName.length > 3 && (/^[a-zA-Z0-9\ ]+$/.test(landName)) && count > 0);
  }

  const { isLoading, error, data } = useQuery(
    ["get-cost", mapStore.selectedTiles.length],
    getMetisCostUSD,
  );

  if (!selectedTiles[0]?.owner) return <></>

  const setApproval = () => {
    if (!validation()) return;
    return appStore.setApprovalForAll();
  };

  const createListing = () => {
    if (!validation()) return;
    return appStore.createList(mapStore.selectedTiles, count, landName);
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
      fullScreen={matches}
      style={{ overflowX: "hidden"}}
    >
      <Header onClose={handleClose} />
      <div className={styles["dialog-content"]}>
        <div className={styles["land-card"]}>
          {[selectedTiles[0]].map(({
            owner,
            coordinates,
            tokenId,
            country,
            countryName,
            isPremium,
          }) => (
            <div
              key={tokenId}
              className={styles["earth-land"]}
            >
              <div className={styles["land-container"]}>
                <div style={{ width: 128, height: 128 }}>
                  <img
                    className={styles["land-img"]}
                    src={formatMapboxUrl(coordinates.x, coordinates.y, ZOOM)}
                    alt="Land"
                  />
                </div>

                <div className={styles["land-info"]}>
                  <div className={styles["land-header"]}>
                    <div className={styles["land-info-name"]}>{landName ? landName : "There’s some name of land group"}</div>
                  </div>
                  <span className={styles["info-text"]}>
                    Owned by: {" "}
                    <a
                      target="_blank"
                      rel="noopener"
                      href={`https://base.blockscout.com/address/${owner}`}
                    >
                      {cutAddress(owner)}
                    </a>
                    {isPremium && <span style={{ color: "#ED9B3B", fontWeight: "bold" }}>Premium</span>}
                  </span>
                    <div className={styles["land-info-country"]}>
                      <span className={styles["info-text"]}>
                        {" "}
                        Country:
                      </span>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <Flag
                          code={country}
                          className={styles["country-flag"]}
                        />
                        <h4 style={{ marginBottom: 0, marginTop: 0 }}>
                        {countryName}
                      </h4>
                </div>
              </div>
            </div>
          </div>
            <div style={{ marginTop: 8 }}>
              <span style={{ fontSize: 18, fontWeight: "bold" }}>Land name</span>
              <Box
                sx={{
                  "& .MuiInput-root": {
                    width: matchesNumTwo ? "89%" : "100%",
                    marginTop: "8px",
                    background: "#E5E3E3",
                    border: "none",
                    padding: "14px 30px",
                    fontSize: 18,
                    outline: "none",
                    fontFamily: 'Space Grotesk',
                  }
                }}
              >
              <Input
                placeholder="Set your own name"
                value={landName}
                onChange={(e) => setLandName(e.target.value)}
                error={landInputError}
              />
                </Box>
            </div>
          </div>
            ))}
        </div>
        <div style={{ display: "flex", flexDirection: "column", padding: "20px 0" }}>
          <div className={styles["listing-header"]}>Place item</div>
          <div className={styles["listing-content"]}>
            <div style={{ fontSize: 18, fontWeight: "bold", marginBottom: 8 }}>Select starting price</div>
            <div className={styles["starting-price-container"]}>
              <Box
                sx={{
                  "& .MuiInput-root": {
                    background: "#E5E3E3",
                    border: "none",
                    padding: "20px 30px",
                    fontSize: 18,
                    outline: "none",
                    fontFamily: 'Space Grotesk',
                    width: 250,
                    height: 63
                  }
                }}
              >
                <Input
                  type="number"
                  placeholder="Enter your price"
                  value={count}
                  onChange={(e) => setCount(Number(e.target.value))}
                  sx={{
                    "& .MuiInput-root": {
                      background: "#E5E3E3",
                      border: "none",
                      padding: "20px 30px",
                      fontSize: 18,
                      outline: "none",
                      fontFamily: 'Space Grotesk',
                      width: 175,
                    }
                  }}
                  error={countInputError}
                />
              </Box>
              <div className={styles["currency-selector"]}>
                <img src={metis} style={{ width: 36, height: 36 }} alt="Cryptocurrency"/>
              </div>
            </div>
              <span className={styles["currency-rate"]}>
                {
                  !isLoading && data && !error ? "$" + ((count || 0) * data).toFixed(2) : "Something went wrong."
                }
              </span>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {appStore.approve ?
                <button className={styles["complete-listing"]} onClick={createListing}>
                  Complete Listing
                </button>
                :
                <button className={styles["complete-listing"]} onClick={setApproval}>
                  Approve
                </button>
              }
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

const Header: React.FC<{
  onClose: () => void;
}> = ({ onClose }) => {
  return (
    <div className={styles["modal-header"]}>
      <span>List item to marketplace</span>
      <CloseIcon onClick={onClose} style={{ cursor: "pointer" }}/>
    </div>
  );
};

export { ListingLandModal };
