import {getValue, setValue, StorageKey} from "../utils/storageUtils";

const address = 'https://api.coingecko.com/api/v3'

interface Result {
    'ethereum': {
        usd: number
    }
}

export async function getMetisCostUSD() {
    const lastRefreshDate = new Date(+(getValue(StorageKey.LAST_REFRESH) ?? '0'))

    if ((lastRefreshDate.getTime() + 15 * 60000) < new Date().getTime() || !getValue(StorageKey.METIS_COST)) {
        const res = await fetch(`${address}/simple/price?ids=ethereum&vs_currencies=usd`)
            .then(res => res.json() as unknown as Result)
            .then(res => res['ethereum'].usd)

        setValue(StorageKey.METIS_COST, res.toString())
        setValue(StorageKey.LAST_REFRESH, new Date().getTime().toString())

        return res
    }

    return +(getValue(StorageKey.METIS_COST) ?? '0')
}
