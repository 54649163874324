.title {
  font-size: 30px;

  margin-top: 5rem;
  margin-bottom: 16px;
}

.gray-block {
  padding: 26px 56px;

  /* background-color: #f3f3f3; */
}

@media screen and (max-width: 999px) {
  .react-draggable {
    position: unset;
    transform: none;
  }
}

@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .react-draggable {
    position: unset;
    transform: none;
  }
}

@media only screen and (width: 393px) and (height: 852px) and (-webkit-device-pixel-ratio: 3) {
  .react-draggable {
    position: unset;
    transform: none;
  }
}

.button {
  border: none;

  /* background-color: #454545; */
  color: #ba15f4;

  font-size: 20px;

  /* padding: 12px; */

  transition: 0.3s ease;
  cursor: pointer;

  border: double 1px transparent;
  border-radius: 16px;

  background-image: linear-gradient(white, white),
    linear-gradient(to right, #ba15f4, #11c9e3);
  background-origin: border-box;
  background-clip: content-box, border-box;

  padding: 1px;
}

.premium-button {
  border-radius: 16px;
  background: var(
    --1,
    linear-gradient(265deg, #11c9e3 -7.11%, #ba15f4 103.55%)
  );
  color: #fff;
}

.button div {
  padding: 8px 14px;
}

.button:hover {
  background-color: #383838;
  color: #aefff1;
  transition: 0.3s ease;
}

.search-block {
  margin: 21px 32px;
  display: flex;
  flex-direction: row;
  background-color: #f3f3f3;
  padding: 18px 20px;
  align-items: center;
}

.search-input {
  background-color: #11c9e3;
}

@media screen and (max-width: 999px) {
  .search-wrap {
    margin-top: 100px;
    margin-bottom: 10px;
  }
}

@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .search-wrap {
    margin-top: 100px;
    margin-bottom: 10px;
  }
}

@media only screen and (width: 393px) and (height: 852px) and (-webkit-device-pixel-ratio: 3) {
  .search-wrap {
    margin-top: 100px;
    margin-bottom: 10px;
  }
}

.owner-block {
  margin: 21px 32px;
  display: flex;
  flex-direction: row;
  border-radius: 16px;
  border: 1px solid #11c9e3;
  background: #fff;

  padding: 18px 20px;

  justify-content: space-between;
  align-items: center;
}

.owner-block h2:nth-child(2) {
  color: #11c9e3;
  font-weight: 600;
  font-size: 18px;
}

.buy-btn:not(:disabled) {
  background-color: #11c9e3;
  color: #fff;
  border-radius: 15px;
  border: none;
  padding: 0 22px;

  transition: 0.2s ease;
  cursor: pointer;
}

.buy-btn:not(:disabled):hover {
  background-color: #67cc7d;

  transition: 0.2s ease;
}

.buy-btn:disabled {
  background-color: #54a466;
  color: #000000;

  border: none;
  padding: 0 22px;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;
}

.buy-btn:disabled > h2 {
  margin-left: 8px;
}

.whats-here-block {
  margin: 21px 32px;
  display: flex;
  flex-direction: column;
  /* background-color: #f3f3f3; */

  justify-content: space-between;

  padding: 18px 20px;
}

.clear-btn {
  background-color: transparent;
  color: black;

  border: none;
  margin: 12px 32px;

  font-size: 16px;
  text-decoration: underline;

  transition: 0.2s ease;
  cursor: pointer;
}

.premium-button {
  background-color: #ed9b3b;
  color: #fff;
}

.premium-button:hover {
  background-color: #f38f19;
  color: #fff;
}

@media screen and (max-width: 1000px) {
  .title {
    margin-top: 1rem;
  }
}

@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .title {
    margin-top: 1rem;
  }
}

@media only screen and (width: 393px) and (height: 852px) and (-webkit-device-pixel-ratio: 3) {
  .title {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 800px) {
  .gray-block {
    padding: 21px 32px;
  }

  .search-block {
    flex-direction: column;
  }

  .search-block > div {
    margin-left: 0;
    width: 100%;
  }
}

@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .gray-block {
    padding: 21px 32px;
  }

  .search-block {
    flex-direction: column;
  }

  .search-block > div {
    margin-left: 0;
    width: 100%;
  }
}

@media only screen and (width: 393px) and (height: 852px) and (-webkit-device-pixel-ratio: 3) {
  .gray-block {
    padding: 21px 32px;
  }

  .search-block {
    flex-direction: column;
  }

  .search-block > div {
    margin-left: 0;
    width: 100%;
  }
}

@media screen and (max-width: 540px) {
  .gray-block > div > button:first-child {
    margin-right: 0 !important;
    margin-bottom: 12px;
  }

  .gray-block > div {
    flex-direction: column !important;
  }

  .owner-block {
    flex-direction: column;
    align-items: flex-start;
  }
  .button {
    width: 100%;
  }

  .premium-button-content {
    justify-content: center;
  }

  .whats-here-block {
    margin: 10px 0;
    padding: 15px;
  }

  .owner-block {
    margin: 10px 0;
    padding: 15px;
  }
}

@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .gray-block > div > button:first-child {
    margin-right: 0 !important;
    margin-bottom: 12px;
  }

  .gray-block > div {
    flex-direction: column !important;
  }

  .owner-block {
    flex-direction: column;
    align-items: flex-start;
  }
  .button {
    width: 100%;
  }

  .premium-button-content {
    justify-content: center;
  }

  .whats-here-block {
    margin: 10px 0;
    padding: 15px;
  }

  .owner-block {
    margin: 10px 0;
    padding: 15px;
  }
}

@media only screen and (width: 393px) and (height: 852px) and (-webkit-device-pixel-ratio: 3) {
  .gray-block > div > button:first-child {
    margin-right: 0 !important;
    margin-bottom: 12px;
  }

  .gray-block > div {
    flex-direction: column !important;
  }

  .owner-block {
    flex-direction: column;
    align-items: flex-start;
  }
  .button {
    width: 100%;
  }

  .premium-button-content {
    justify-content: center;
  }

  .whats-here-block {
    margin: 10px 0;
    padding: 15px;
  }

  .owner-block {
    margin: 10px 0;
    padding: 15px;
  }
}
