import { makeAutoObservable, toJS } from "mobx";
import { Map } from "leaflet";
import { getValue, StorageKey } from "../utils/storageUtils";
import { Tile, TileTypes } from "../utils/mapUtils";

export class MapStore {
  selectedTiles: number[] = [];
  selectedTilesTypes: TileTypes[] = [];
  purchasedTiles: number[] = [];
  popupOpened = false;
  popupBound: [number, number] | null = null;
  popupToOpen: any | null = null;
  bounds: [number, number, number, number] = [0, 0, 0, 0];
  map?: Map = undefined;
  clearFunc: (() => void) | undefined = undefined;
  displayGrid =
    (getValue(StorageKey.DISPLAY_GRID) ?? "true") === "true" ? true : false;

  constructor() {
    makeAutoObservable(this);
  }

  get availableToBuy() {
    return this.selectedTilesTypes.every(
      (val) => val === this.selectedTilesTypes[0],
    );
  }

  get firsTileType() {
    return toJS(this.selectedTilesTypes)[0];
  }

  get popupToOpen1() {
    return toJS(this.popupToOpen);
  }
}

export const mapStore = new MapStore();
