import React, {CSSProperties, useRef} from 'react';
import styles from './styles.module.css'

export interface SearchProps {
    timeout?: number
    children?: React.ReactNode

    query?: string
    onQueryChanged?: (query: string) => void
    placeholder?: string

    className?: string
    style?: CSSProperties
}

export const Search = (props: SearchProps): React.ReactElement => {
    const timeoutId = useRef(0)

    function timeoutSearch(value: string) {
        clearTimeout(timeoutId.current)

        timeoutId.current = setTimeout(() => {
            props.onQueryChanged?.(value)
        }, props.timeout ?? 500) as unknown as number
    }

    return (
        <div className={`${styles['search-block']} ${props.className ?? ''}`} style={props.style}>
            <input
                value={props.query}
                className={styles['search-input']}
                onChange={e => timeoutSearch(e.currentTarget.value)}
                placeholder={props.placeholder}
            />

            {props.children ? (
                <div className={styles['search-results']}>
                    {props.children}
                </div>
            ) : null}
        </div>
    );
};
