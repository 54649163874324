import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";
import { useRef, useEffect, useState } from "react";
import "./Map3d.css";
import { getGroups } from "../../api/parser";
import { index2coords, tileToCoords } from "../../utils/mapUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import crown from "../../img/crown_marker.svg";
import { StorageKey, getValue, setValue } from "../../utils/storageUtils";
import { SearchBox } from "@mapbox/search-js-react";
import { Link } from "react-router-dom";
import { faBinoculars } from "@fortawesome/free-solid-svg-icons";

const accessToken =
  "pk.eyJ1IjoicmlnZ3lib2R5IiwiYSI6ImNscm5wNXVkdjEwMzcyaXF6Y2d4NDYydWoifQ.F1hWyYafEpgZgqFrfX8YUA";

mapboxgl.accessToken = accessToken;

function Map3d() {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const searchbar = useRef(null);
  // const [lng, setLng] = useState(-70.9);
  // const [lat, setLat] = useState(42.35);
  // const [zoom, setZoom] = useState(2);
  const [theme, setTheme] = useState("day");
  const [tiles, setTiles] = useState({});
  const centerRaw = getValue(StorageKey.CENTER_POSITION);
  const zoomRaw = getValue(StorageKey.ZOOM);
  var isDraw = true;
  function onHandleTheme(e) {
    if (theme === "day") {
      setTheme("night");
      map.current.setConfigProperty("basemap", "lightPreset", "night");
    }
    if (theme === "night") {
      setTheme("day");
      map.current.setConfigProperty("basemap", "lightPreset", "dat");
    }
  }

  async function addMarkers() {
    if (!mapContainer) return;

    const groups = await getGroups();

    const groupMarkers = [];

    if (groups && Object.keys(groups).length > 0) {
      for (const group of Object.keys(groups)) {
        const coords = index2coords(groups[group]);
        const popupLatLng = tileToCoords(coords);

        const crownEl = document.createElement("div");
        crownEl.classList.add("marker");
        crownEl.style.backgroundImage = `url(${crown})`;
        crownEl.style.backgroundSize = "contain";
        crownEl.style.width = "60px";
        crownEl.style.height = "49px";

        groupMarkers.push({ coords: { ...popupLatLng }, name: group });
        let popup = new mapboxgl.Popup({ offset: 25 }).setText(`${group}`);
        let marker = new mapboxgl.Marker({
          scale: 0.6,
          element: crownEl,
        })
          .setLngLat([popupLatLng.lng, popupLatLng.lat])
          .setPopup(popup)
          .addTo(map.current);
      }
    }
  }

  async function tileAr() {
    const tiles2 = await createTiles(
      map.current?.getBounds(),
      map.current?.getZoom()
    );

    if (!tiles2) return;
    return tiles2;
  }

  function hideMarkers() {
    const markerEl = document.querySelectorAll(".marker");
    markerEl.forEach((item) => (item.style.display = "none"));
  }

  function showMarkers() {
    const markerEl = document.querySelectorAll(".marker");
    markerEl.forEach((item) => (item.style.display = "unset"));
  }

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      center: centerRaw ? JSON.parse(centerRaw) : [51.5, -0.09],
      zoom: zoomRaw ? parseFloat(zoomRaw) : 0,
      width: "100%",
    })
      .on("style.load", () => {
        map.current.setConfigProperty("basemap", "lightPreset", theme);
        addMarkers();
      })
      .on("zoom", async () => {
        setValue(StorageKey.ZOOM, JSON.stringify(map.current.getZoom()));

        if (Math.floor(map.current.getZoom()) === 13) {
          hideMarkers();
        }
        if (Math.floor(map.current.getZoom()) === 12) {
          showMarkers();
        }
      })
      .on("moveend", () => {
        setValue(
          StorageKey.CENTER_POSITION,
          JSON.stringify(map.current.getCenter())
        );
      });

    SearchBox.onload = function () {
      // map.current.addControl(searchbar.current.children[0]);
      searchbar.current.children[0].bindMap(map.current);
    };
  }, []);

  return (
    <div
      style={{
        width: "100wh",
        height: "100vh",
        position: "relative",
      }}
    >
      <form
        className="search"
        ref={searchbar}
        onClick={(e) => {
          e.preventDefault();
          searchbar.current.children[0].bindMap(map.current);
        }}
      >
        <SearchBox
          value=""
          accessToken={accessToken}
          mapboxgl={mapboxgl}
          marker={false}
        />
      </form>

      <button
        style={{
          position: "absolute",
          top: `133px`,
          right: "0",
          zIndex: "99",
          background: `${theme === "day" ? "#0093ffd9" : "#ba5151"}`,
          cursor: "pointer",
          border: "1px solid white",
          borderRadius: "10px",
        }}
        onClick={onHandleTheme}
      >
        {theme === "day" ? `🌙` : `☀️`}
      </button>
      <Link
        to="/explore"
        style={{
          display: "flex",
          justifyContent: "flex-start",
          textDecoration: "none",
        }}
      >
        <button className={"button-link"}>
          <FontAwesomeIcon icon={faBinoculars} className={"link-icon"} />
          Buy land
        </button>
      </Link>
      <div
        style={{
          width: "100wh",
          height: "100vh",
        }}
        ref={mapContainer}
        className="map-container"
      />
    </div>
  );
}

export default Map3d;
