export const ZOOM = 17;
export const MAX_GRID_ZOOM = 14;

export const WORLD_WIDTH = Math.sqrt(4 ** ZOOM);
export const MAPBOX_TILE_URL = `https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}@2x.webp?access_token=${process.env["MAPBOX_TOKEN"]}`;

export function formatMapboxUrl(x: number, y: number, z: number): string {
  return MAPBOX_TILE_URL.replace("{x}", x.toString())
    .replace("{y}", y.toString())
    .replace("{z}", z.toString());
}

// [lat, lng]
export type Point = [number, number];

export enum TileState {
  DEFAULT,
  IN_SELECTION,
  SELECTED,
  OWNED,
}

export enum TileTypes {
  Premium = "PREMIUM",
  Regular = "REGULAR",
}

export interface Tile {
  bounds: [Point, Point];
  state: TileState;
  index: number;

  owner?: string;
  country?: string;
  countryName?: string;
  isPremium?: boolean;
  isMinted?: boolean;
}

export function lon2tile(lon: number, zoom: number) {
  return Math.floor(((lon + 180) / 360) * Math.pow(2, zoom));
}
export function lat2tile(lat: number, zoom: number) {
  return Math.floor(
    ((1 -
      Math.log(
        Math.tan((lat * Math.PI) / 180) + 1 / Math.cos((lat * Math.PI) / 180)
      ) /
        Math.PI) /
      2) *
      Math.pow(2, zoom)
  );
}

export function tile2long(x: number, z: number) {
  return (x / Math.pow(2, z)) * 360 - 180;
}

export function tile2lat(y: number, z: number) {
  const n = Math.PI - (2 * Math.PI * y) / Math.pow(2, z);
  return (180 / Math.PI) * Math.atan(0.5 * (Math.exp(n) - Math.exp(-n)));
}

export function coordsToTile(coords: { lng: number; lat: number }) {
  return {
    x: lon2tile(coords.lng, ZOOM),
    y: lat2tile(coords.lat, ZOOM),
  };
}

export function tileToCoords(tile: { x: number; y: number }) {
  return {
    lng: tile2long(tile.x, ZOOM),
    lat: tile2lat(tile.y, ZOOM),
  };
}

export function coords2index(x: number, y: number) {
  return x + WORLD_WIDTH * y;
}

export function index2coords(index: number) {
  return {
    y: Math.floor(index / WORLD_WIDTH),
    x: index % WORLD_WIDTH,
  };
}

export function getOwnerColor(owner: string): string {
  let color = Math.floor(
    Math.abs(Math.sin(parseInt(owner.slice(2, 8), 16)) * 16777215)
  ).toString(16);
  while (color.length < 6) {
    color = "0" + color;
  }

  return `#${color}`;
}

export function getTileFillOpacity(tile: Tile) {
  if (tile.isPremium) {
    return 0.4;
  }
  switch (tile.state) {
    case TileState.IN_SELECTION: {
      return 0.6;
    }
    case TileState.SELECTED: {
      return 0.6;
    }
    case TileState.OWNED: {
      return 0.4;
    }
    default: {
      return 0.1;
    }
  }
}

export function getTileWeight(tile: Tile) {
  switch (tile.state) {
    case TileState.IN_SELECTION: {
      return 0.5;
    }
    case TileState.SELECTED: {
      return 0.5;
    }
    case TileState.OWNED: {
      return 0.2;
    }
    default: {
      return 0.5;
    }
  }
}

export function getTileColor(tile: Tile, displayGrid: boolean) {
  if (tile.isPremium) {
    switch (tile.state) {
      case TileState.IN_SELECTION: {
        return "cyan";
      }
      case TileState.SELECTED: {
        return "blue";
      }
      case TileState.OWNED: {
        return tile.owner === "0x0000000000000000000000000000000000000000"
          ? "#ED9B3B"
          : getOwnerColor(tile.owner ?? "red");
      }
      default: {
        return "#ED9B3B";
      }
    }
  } else {
    switch (tile.state) {
      case TileState.IN_SELECTION: {
        return "cyan";
      }
      case TileState.SELECTED: {
        return "blue";
      }
      case TileState.OWNED: {
        return getOwnerColor(tile.owner ?? "red");
      }
      default: {
        if (displayGrid) {
          return "white";
        }

        return "transparent";
      }
    }
  }
}

interface SquareBorders {
  fromX: number;
  fromY: number;

  toX: number;
  toY: number;
}

type LatLng = { lat: number; lng: number };

export function getSquareBorders(pos1: LatLng, pos2: LatLng): SquareBorders {
  const tileCoords = coordsToTile(pos1);
  const secondTileCoords = coordsToTile(pos2);

  return {
    fromX:
      tileCoords.x < secondTileCoords.x ? tileCoords.x : secondTileCoords.x,
    fromY:
      tileCoords.y < secondTileCoords.y ? tileCoords.y : secondTileCoords.y,

    toX: tileCoords.x > secondTileCoords.x ? tileCoords.x : secondTileCoords.x,
    toY: tileCoords.y > secondTileCoords.y ? tileCoords.y : secondTileCoords.y,
  };
}
