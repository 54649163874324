import React, { useEffect, useRef, useState } from "react";
import { EarthMap } from "../../components/Map/EarthMap";
import { LandsMenu } from "./LandsMenu";
import { mapStore } from "../../store/mapStore";
import { appStore } from "../../store/appStore";
import { getValue, setValue, StorageKey } from "../../utils/storageUtils";
import useMediaQuery from "@mui/material/useMediaQuery";
import { tileToCoords } from "../../utils/mapUtils";
import { LatLngTuple, Point } from "leaflet";
import { useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";

import "react-reflex/styles.css";
import { isMobile } from "../../const/const";
import { Rnd } from "react-rnd";
import { AddressSearch } from "./components/AddressSearch/AddressSearch";
import styles from "./styles.module.css";

function getSizes(isMobile: boolean) {
  const landsMenu = parseFloat(
    getValue(
      isMobile ? StorageKey.LANDS_BLOCK_HEIGHT : StorageKey.LANDS_BLOCK_WIDTH
    ) ?? "400"
  );

  if (isMobile) {
    return {
      map: window.screen.height - landsMenu,
      landsMenu,
    };
  }

  return {
    map: document.body.offsetWidth - landsMenu,
    landsMenu,
  };
}

const WithMapHOC = observer(
  ({ mapStore, children }: { mapStore: any; children: any }) => {
    const { search, state } = useLocation();

    useEffect(() => {
      const queryParams = new URLSearchParams(search);
      if (queryParams.get("x") && queryParams.get("y") && mapStore.map) {
        if (state && (state as any).isDontNeedZoom) {
          return;
        }
        const tile = tileToCoords({
          x: Number(queryParams.get("x")),
          y: Number(queryParams.get("y")),
        });

        const bound: LatLngTuple = [tile.lat, tile.lng];
        mapStore.map.setView(bound, isMobile ? 16 : 15);

        if (!isMobile) {
          mapStore.popupBound = tile;
        }

        if (isMobile) {
          setTimeout(() => {
            var offset = 55;
            mapStore.map.panBy(new Point(offset, 60), { animate: false });
          }, 100);
          mapStore.popupBound = tile;
        }
      }
    }, [search, mapStore.map, state]);

    return <>{children}</>;
  }
);

export const Explore = (): React.ReactElement => {
  const matches = useMediaQuery("(max-width:690px)");
  const [show, setIsShow] = useState(true);
  const [sizes, setSizes] = useState(getSizes(isMobile));

  // force rerender
  useEffect(() => {
    if (!show) {
      setIsShow(true);
    }
  }, [show]);

  return (
    <WithMapHOC mapStore={mapStore}>
      {isMobile ? (
        <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
          <div
            className={styles["search-wrap"]}
            style={{ height: "fit-content" }}
          >
            <AddressSearch mapStore={mapStore} />
          </div>

          {show && (
            <div style={{ width: "100%", height: sizes.map }}>
              <EarthMap isMobile={isMobile} mapStore={mapStore} />
            </div>
          )}

          <Rnd
            disableDragging={true}
            default={{
              x: 0,
              y: sizes.map + 60,
              width: "100%",
              height: sizes.landsMenu,
            }}
            minHeight={300}
            enableResizing={{
              top: !matches,
            }}
            onResize={(e, dir, ref) => {
              setValue(StorageKey.LANDS_BLOCK_HEIGHT, ref.style.height);
              setSizes(getSizes(isMobile));
            }}
            onResizeStop={() => {
              setIsShow(false);
            }}
          >
            <LandsMenu
              appStore={appStore}
              mapStore={mapStore}
              isMobile={isMobile}
            />
          </Rnd>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flex: 1,
            position: "relative",
            background: "#cae9f57a",
          }}
        >
          {show && (
            <div style={{ width: sizes.map }}>
              <EarthMap isMobile={isMobile} mapStore={mapStore} />
            </div>
          )}

          <Rnd
            disableDragging={true}
            default={{
              x: sizes.map,
              y: 0,
              width: sizes.landsMenu,
              height: "100%",
            }}
            minWidth={400}
            enableResizing={{
              left: true,
            }}
            onResize={(e, dir, ref) => {
              setValue(StorageKey.LANDS_BLOCK_WIDTH, ref.style.width);
              setSizes(getSizes(isMobile));
            }}
            onResizeStop={() => {
              setIsShow(false);
            }}
          >
            <LandsMenu
              appStore={appStore}
              mapStore={mapStore}
              isMobile={isMobile}
            />
          </Rnd>
        </div>
      )}
    </WithMapHOC>
  );
};
